import React, { useState, useEffect } from "react";
import styles from './CookieBanner.module.css'

const CookieBanner = () => {
    const [isBannerVisible, setBannerVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookieConsent");
        if (!consent) {
            setBannerVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "true");
        setBannerVisible(false);

        // Initialize Google Analytics here, if you haven't already
        const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
        if (trackingId) {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", trackingId);
        } else {
            console.error("Google Analytics tracking ID is not defined.");
        }
    };

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "false");
        setBannerVisible(false);
    };

    if (!isBannerVisible) return null;

    return (
        <div className={styles.bannerStyle}>
            <p className="mb-2 mt-2">
                We use local storage, session storage, and Google Analytics to improve
                your experience. By clicking "Accept", you consent to the use of these
                technologies.
            </p>
            <div className="pb-2">
                <button onClick={handleAccept} className={styles.buttonStyle}>Accept</button>
                <button onClick={handleDecline} className={styles.buttonStyle}>Decline</button>
            </div>
        </div>
    );
};

export default CookieBanner;
