import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WorxAPIService from '../Api/worxAPIService';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './worxDetail.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from './components/header';
import HoverEffectDiv from '../effects/HoverEffectDiv';
import ButtonEffectHover from '../effects/HoverEffectButton';
import ResponsiveAppBar from './components/header/header_mui';

const MyGoogleMap = ({ center, zoom }) => {
    const mapRef = React.useRef(null);

    React.useEffect(() => {
        const loadGoogleMapsScript = (callback) => {
            if (window.google) {
                callback();
                return;
            }

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCNilZfl5ySD5GmeE2PZn9eZNg5BsaRqkg`;
            script.async = true;
            script.defer = true;
            document.head.append(script);

            script.onload = () => callback();
        };

        loadGoogleMapsScript(() => {
            if (mapRef.current) {
                const map = new window.google.maps.Map(mapRef.current, {
                    center,
                    zoom,
                    mapTypeId: 'roadmap',
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    zoomControl: true,
                    draggable: false
                });

                new window.google.maps.Marker({
                    position: center,
                    map,
                    title: 'Hotel Location',
                });
            }
        });
    }, [center, zoom]);

    return (
        <div ref={mapRef} className={styles.mapContainer} />
    );
};

const WorxDetailPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const worxId = queryParams.get('spaceId');
    const [worx, setWorx] = useState(null);
    const worxAPIService = new WorxAPIService();
    const state = location.state || {};
    const { search, isFree } = state;
    const [selectedHours, setSelectedHours] = useState(1);
    const [startTime, setStartTime] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date(search.day));
    const [availabilityMessage, setAvailabilityMessage] = useState('');
    const [minPriceData, setMinPriceData] = useState({});
    const [worxBookings, setWorxBookings] = useState({});
    const [bestValue, setBestValue] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const bookingSectionRef = useRef(null);

    // Create a ref for the booking button
    const bookingButtonRef = useRef(null);

    useEffect(() => {
        const fetchWorxDetails = async () => {
            const getBestValue = () => {
                if (!worx || !worx.SpacePrices || worx.SpacePrices.length === 0) return null;

                return worx.SpacePrices.reduce((best, price) => {
                    const value = parseFloat(price.price) / price.hour;
                    return value < best.value ? { ...price, value } : best;
                }, { value: Infinity });
            };

            try {
                const worxDetails = await worxAPIService.getWorx(parseInt(worxId));
                setWorx(worxDetails.body);
                setMinPriceData(worxDetails.body.SpacePrices.reduce((min, price) => {
                    return parseFloat(price.price) < parseFloat(min.price) ? price : min;
                }));
                console.log(minPriceData)
                setBestValue(getBestValue());
            } catch (error) {
                console.error('Error fetching worx details:', error);
            }
        };

        const fetchBookingDetails = async () => {
            const bookingDetails = await worxAPIService.getAllFutureBookings(parseInt(worxId));
            setWorxBookings(bookingDetails.body);
        }

        fetchWorxDetails();
        fetchBookingDetails();
    }, [worxId]);


    useEffect(() => {
        const availabilityResult = checkAvailability();
        setAvailabilityMessage(availabilityResult.message);
    }, [selectedDate, startTime, selectedHours]);

    useEffect(() => {
        if (!worx) return;

        const dayOfWeek = selectedDate.toLocaleString('en-us', { weekday: 'long' }).toLowerCase();
        const { openingTime, closingTime } = getAvailabilityForDay(dayOfWeek);
        const timeSlots = generateTimeSlots(openingTime, closingTime);

        // Set the default startTime to the minimum available time slot if not already set
        if (timeSlots.length > 0 && !startTime) {
            setStartTime(timeSlots[0]);
        }

        const availabilityResult = checkAvailability();
        setAvailabilityMessage(availabilityResult.message);
    }, [selectedDate, worx]);

    const incrementHours = () => {
        if (selectedHours < 24) {
            setSelectedHours(selectedHours + 1);
        }
    };

    const decrementHours = () => {
        if (selectedHours > 1) {
            setSelectedHours(selectedHours - 1);
        }
    };

    const getAvailabilityForDay = (dayOfWeek) => {
        const availabilityKey = `opening_time_${dayOfWeek}`;
        const closingKey = `closing_time_${dayOfWeek}`;
        const openingTime = worx?.SpaceAvailability[availabilityKey] || '09:00';
        const closingTime = worx?.SpaceAvailability[closingKey] || '18:00';

        return { openingTime, closingTime };
    };

    const generateTimeSlots = (openingTime, closingTime) => {
        const timeSlots = [];
        let [openingHour, openingMinute] = openingTime.split(':').map(Number);
        const [closingHour, closingMinute] = closingTime.split(':').map(Number);

        const now = new Date();
        const isToday = selectedDate.toDateString() === now.toDateString();

        if (isToday) {
            openingHour = now.getHours();
            openingMinute = Math.ceil(now.getMinutes() / 15) * 15;
            if (openingMinute === 60) {
                openingMinute = 0;
                openingHour += 1;
            }
        }

        while (openingHour < closingHour || (openingHour === closingHour && openingMinute < closingMinute)) {
            timeSlots.push(`${openingHour.toString().padStart(2, '0')}:${openingMinute.toString().padStart(2, '0')}`);
            openingMinute += 15;
            if (openingMinute >= 60) {
                openingMinute = 0;
                openingHour += 1;
            }
        }

        return timeSlots;
    };

    useEffect(() => {
        if (worx) {
            const dayOfWeek = selectedDate.toLocaleString('en-us', { weekday: 'long' }).toLowerCase();
            const { openingTime, closingTime } = getAvailabilityForDay(dayOfWeek);
            const timeSlots = generateTimeSlots(openingTime, closingTime);

            if (timeSlots.length > 0 && !startTime) {
                setStartTime(timeSlots[0]);
            }
        }
    }, [worx, selectedDate]);

    const checkAvailability = () => {
        if (!worx) return { available: false, message: 'Worx data not available', availableUnits: 0 };

        const dayOfWeek = selectedDate.toLocaleString('en-us', { weekday: 'long' }).toLowerCase();
        const { openingTime, closingTime } = getAvailabilityForDay(dayOfWeek);

        const [openingHour, openingMinute] = openingTime.split(':').map(Number);
        const [closingHour, closingMinute] = closingTime.split(':').map(Number);

        const [hours, minutes] = startTime.split(':').map(Number);
        const startDate = new Date(selectedDate);
        startDate.setHours(hours);
        startDate.setMinutes(minutes);

        const endDate = new Date(startDate);
        endDate.setHours(startDate.getHours() + selectedHours);

        const openingDate = new Date(startDate);
        openingDate.setHours(openingHour, openingMinute);

        const closingDate = new Date(startDate);
        closingDate.setHours(closingHour, closingMinute);

        if (startDate < openingDate) {
            return { available: false, message: `Opening time is ${openingTime}`, availableUnits: 0 };
        }

        if (endDate > closingDate) {
            const availableHours = closingHour - hours;
            setSelectedHours(availableHours > 0 ? availableHours : 1);
            return { available: false, message: `Closing time is ${closingTime}`, availableUnits: 0 };
        }

        let overlappingBookings = [];

        if (Array.isArray(worxBookings)) {
            overlappingBookings = worxBookings.filter(booking => {
                const bookingStart = new Date(`${booking.bookingDate}T${booking.from_time}`);
                const bookingEnd = new Date(`${booking.bookingDate}T${booking.to_time}`);

                const case1 = bookingStart.getTime() === startDate.getTime();
                const case2 = bookingStart > startDate && bookingStart < endDate;
                const case3 = bookingEnd > startDate && bookingEnd < endDate;
                const case4 = bookingStart <= startDate && bookingEnd >= endDate;

                return case1 || case2 || case3 || case4;
            });
        }

        const availableUnits = worx.numberOfUnits - overlappingBookings.length;

        if (availableUnits <= 0) {
            return { available: false, message: 'No available spaces during this time slot.', availableUnits: 0, seatNumber: null };
        } else {
            const seatNumber = overlappingBookings.length + 1;
            return {
                available: true,
                message: `${availableUnits} ${availableUnits === 1 ? 'space' : 'spaces'} available.`,
                availableUnits,
                seatNumber
            };
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const getAllOpeningTimes = () => {
        const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        return daysOfWeek.map(day => {
            const { openingTime, closingTime } = getAvailabilityForDay(day);
            return (
                <div key={day} className="flex justify-between">
                    <p className="capitalize">{day}:</p>
                    <p>{openingTime} - {closingTime}</p>
                </div>
            );
        });
    };

    const getPrice = () => {
        const priceData = worx.SpacePrices.find(price => price.hour === selectedHours);
        return priceData ? priceData.price : '0.00';
    };

    const handleBookButtonClick = () => {
        const availabilityResult = checkAvailability();

        if (!availabilityResult.available) {
            setAvailabilityMessage(availabilityResult.message);
            return;
        }

        if (!startTime) {
            setAvailabilityMessage('Please select a start time.');
            return;
        }

        if (!selectedHours) {
            setAvailabilityMessage('Please select the number of hours you wish to book.');
            return;
        }

        navigate('/space/book', {
            state: {
                worxId,
                worx,
                day: selectedDate,
                numPeople: search.numPeople,
                space: worx.Space,
                numHours: selectedHours,
                price: getPrice(),
                from_time: startTime,
                isFree: isFree || getPrice() == '0.00',
                seatNumber: availabilityResult.seatNumber
            },
        });
    };

    const handlePriceCardClick = (hours, price) => {
        setSelectedHours(hours);
        setAvailabilityMessage('');

        // Scroll to booking button if on mobile
        if (window.innerWidth <= 768) { // Adjust based on your breakpoint
            bookingButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    if (!worx) {
        return <div>Loading...</div>;
    }

    const dayOfWeek = selectedDate.toLocaleString('en-us', { weekday: 'long' }).toLowerCase();
    const { openingTime, closingTime } = getAvailabilityForDay(dayOfWeek);
    const timeSlots = generateTimeSlots(openingTime, closingTime);

    return (
        <div className={`${styles.bgGray100} min-h-screen flex flex-col`}>
            <ResponsiveAppBar />
            <div className={styles.container}>
                <div className={styles.imageContainer}>
                    <img
                        src={worx.WorxPhotos.length > 0 ? worx.WorxPhotos[0].photo_url : "https://via.placeholder.com/400x300"}
                        alt="Room"
                        className={styles.image}
                    />
                    <div className={styles.priceBadge}>
                        {isFree || minPriceData.price == '0.00' ? 'Free' : `€${minPriceData.price}*/${minPriceData.hour}hr${minPriceData.hour !== 1 ? 's' : ''}`}
                    </div>
                </div>
                <div className={`${styles.columns}`}>
                    <div className={styles.leftColumn}>
                        <h2 className={styles.header}>{worx.name}</h2>
                        <h2 className={styles.subHeader}>{worx.Space.name}</h2>
                        <p className={styles.address}>{worx.Space.address}</p>
                        <div className={styles.availability} onClick={toggleCollapse}>
                            Opening time: {openingTime}, Closing time: {closingTime}
                            <ExpandMoreIcon className={`opening-times-chevron ${isCollapsed ? '' : 'expanded'}`} />
                        </div>
                        <div className={`${styles.availabilityDetails} ${isCollapsed ? '' : 'expanded'}`}>
                            {getAllOpeningTimes()}
                        </div>
                        <div className={styles.priceList}>
                            <h3 className={styles.priceTitle}>Price List</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-3">
                                {worx.SpacePrices.map(price => (
                                    <div
                                        key={price.hour}
                                        className={`${styles.priceCard} ${selectedHours === price.hour ? styles.selectedPriceCard : ''}`}
                                        onClick={() => handlePriceCardClick(price.hour, price.price)}
                                    >
                                        <p>{price.hour} hour{price.hour > 1 ? 's' : ''}</p>
                                        {
                                            price.price == '0.00' ? <p className={styles.priceValue}>Free</p> : <p className={styles.priceValue}>€{price.price}</p>
                                        }
                                        {bestValue && bestValue.hour === price.hour && price.hour !== '0.00' && (
                                            <span className={styles.bestValueBadge}>Best Value</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <p className={styles.tos}>*Price may be subject to applicable tax.</p>
                        </div>

                        <div className={styles.amenities}>
                            <h3 className={styles.aminitiesTitle}>Amenities</h3>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                                {worx.Amenities.map(amenity => (
                                    <div key={amenity.id} className={styles.amenity}>
                                        <img src={`/amenities/${amenity.icon}`} alt={amenity.name} className={styles.amenityIcon} />
                                        <p className={styles.amenitiesP}>{amenity.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-6">
                            <p className={styles.worxDescription}>{worx.description}</p>
                        </div>

                        <MyGoogleMap center={{ lat: worx.Space.lat, lng: worx.Space.lng }} zoom={15} />
                    </div>

                    <div className={styles.rightColumn} ref={bookingSectionRef}>
                        <div className={styles.bookingSection}>
                            <h3 className={styles.bookingSectionTitle}>Book this space</h3>
                            <div className={styles.bookingSectionCalendar}>
                                <FaCalendarAlt className={styles.calendar} />
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="MMMM d, yyyy"
                                    className={styles.datePicker}
                                    minDate={new Date()}
                                />
                            </div>

                            <div className={styles.timeSection}>
                                <div className="flex-1">
                                    <label className={styles.hours}>Start Time:</label>
                                    <select
                                        value={startTime}
                                        onChange={(e) => setStartTime(e.target.value)}
                                        className={styles.timeSelect}
                                    >
                                        {timeSlots.map(time => (
                                            <option key={time} value={time}>{time}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex-1 flex flex-col items-start">
                                    <label className={styles.hours}>Hours:</label>
                                    <div className={styles.hoursSelector}>
                                        <button
                                            onClick={decrementHours}
                                            className={styles.decrementButton}
                                            disabled={selectedHours <= 1}
                                        >
                                            -
                                        </button>
                                        <div>{selectedHours}</div>
                                        <button
                                            onClick={incrementHours}
                                            className={styles.incrementButton}
                                            disabled={selectedHours >= 24}
                                        > +
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {
                                availabilityMessage && (
                                    <div className={`${styles.availabilityMessage} ${availabilityMessage.includes('booked') || availabilityMessage.includes('Closing time') || availabilityMessage.includes('No available spaces') ? styles.availabilityMessageNotAvailable : styles.availabilityMessageAvailable}`}>
                                        {availabilityMessage}
                                    </div>
                                )
                            }
                            <div className={styles.buttonDiv} ref={bookingButtonRef}>
                                <ButtonEffectHover
                                    text={`Book for ${isFree || getPrice() == '0.00' ? 'Free' : `€${getPrice()}`}`}
                                    onClick={handleBookButtonClick}
                                    isSelected={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorxDetailPage;
