import ReactGA from 'react-ga4';
import { Device } from '@capacitor/device';

// Initialize Google Analytics 4
export const initGA = async (trackingID) => {
    ReactGA.initialize(trackingID);

    ReactGA.event({
        category: 'App Start',
        action: 'Opened App',
        label: 'website'
    });
};

// Track a page view
export const trackPage = (page) => {
    ReactGA.send({ hitType: 'pageview', page });
};

// Track an event
export const trackEvent = (category, action) => {
    ReactGA.event({
        category,
        action,
    });
};
