import React, { useState, useEffect } from 'react';
import { uploadVendorLogoAndGetURL } from "../Api/firebaseService"; // Create this function in firebaseService for hotel logos
import { SpaceAPIService } from '../Api/spaceAPIService';

interface VendorLogoUploadComponentProps {
    vendorId: number;
    existingLogoUrl?: string; // Optional prop for an existing logo URL
}

const VendorLogoUploadComponent: React.FC<VendorLogoUploadComponentProps> = ({
    vendorId,
    existingLogoUrl
}) => {
    const [logoUrl, setLogoUrl] = useState(existingLogoUrl || '');

    const vendorAPIService = new SpaceAPIService();

    useEffect(() => {
        if (existingLogoUrl) {
            setLogoUrl(existingLogoUrl);
        }
    }, [existingLogoUrl]);

    const handleLogoChange = async (file: File) => {
        let uploadedLogoUrl = '';

        console.log(vendorId)

        try {

            // Upload the new logo and get URL
            uploadedLogoUrl = await uploadVendorLogoAndGetURL(file, vendorId);

            // Set the uploaded logo URL as the new logo
            setLogoUrl(uploadedLogoUrl);

            const spaceData = {
                hotel_logo: uploadedLogoUrl
            }

            const response = await vendorAPIService.updateSpace(vendorId, spaceData);
            console.log("Hotel logo uploaded", response);

        } catch (error) {
            console.error("Error uploading hotel logo: ", error);
        }
    };

    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            await handleLogoChange(file);
        }
    };

    return (
        <div className="logo-upload-wrapper" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div className="logo-upload-container" style={{
                border: logoUrl ? '2px solid #4CAF50' : '2px dashed gray',
                padding: '15px',
                width: '75vw',
                height: '200px', // Slightly smaller height for a logo
                backgroundImage: `url(${logoUrl})`,
                backgroundSize: 'contain', // Contain the logo within the box
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
            </div>

            <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                id="logo-upload"
            />
            <label htmlFor="logo-upload" style={{
                marginTop: '15px',
                cursor: 'pointer',
                padding: '10px 20px',
                backgroundColor: logoUrl ? '#FFA500' : '#007BFF',
                color: 'white',
                borderRadius: '5px',
                fontSize: '16px',
                textAlign: 'center',
            }}>
                {logoUrl ? 'Update Logo' : 'Upload Logo'}
            </label>
        </div>
    );
};

export default VendorLogoUploadComponent;
