import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CheckoutForm from './stripe/CheckoutForm';
import WorxAPIService from '../Api/worxAPIService';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import WorxinnMap from '../Components/map';
import { SERVER_URL } from '../Utils/constants';
import { ClipLoader } from 'react-spinners';
import Header from './components/header';
import HoverEffectDiv from '../effects/HoverEffectDiv';
import styles from './WorxBookingPage.module.css';
import ResponsiveAppBar from './components/header/header_mui';

const WorxBookingPage = () => {
    const location = useLocation();
    const state = location.state || {};
    const { worxId, day, numPeople, space, worx, numHours, price = 0, from_time, isFree, seatNumber } = state;
    const worxAPIService = new WorxAPIService();
    const [worxDetails, setWorxDetails] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [stripePromise, setStripePromise] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const taxRate = 0.23;
    const taxAmount = price * (taxRate / (1 + taxRate));
    const preTaxPrice = price - taxAmount;

    const totalPrice = isFree ? 0 : Number(price);
    const discount = isFree ? Number(price) : 0;

    useEffect(() => {
        const fetchWorxDetails = async () => {
            try {
                const worxDetails = await worxAPIService.getWorx(worxId);
                setWorxDetails(worxDetails.body);

                const response = await fetch(`${SERVER_URL}stripe/create-payment-intent`, {
                    method: 'GET',
                });
                const { clientSecret } = await response.json();
                setClientSecret(clientSecret);

                fetch(`${SERVER_URL}stripe/config`).then(async (r) => {
                    const { publishableKey } = await r.json();
                    setStripePromise(loadStripe(publishableKey));
                });

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching worx details:', error);
            }
        };
        if (worxId) {
            fetchWorxDetails();
        }
    }, [worxId]);

    const calculateToTime = (fromTime, numHours) => {
        if (!fromTime || !numHours) return 'Not specified';

        const [hours, minutes] = fromTime.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        date.setHours(date.getHours() + numHours);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    return (
        <div className={`${styles.appContainer} ${styles.flex} ${styles.flexCol}`}>
            <ResponsiveAppBar />
            <div className={`${styles.flexGrow} ${styles.overflowAuto}`}>
                <div className={styles.container}>
                    <div className={`${styles.flex} ${styles.flexRow} ${styles.gap8}`}>
                        <div className={`${styles.bgWhite} ${styles.p6} ${styles.flexInformation}`}>
                            <div className={`${styles.bookingImageContainer}`}>
                                <img
                                    src={worxDetails?.WorxPhotos[0]?.photo_url || 'https://via.placeholder.com/400'}
                                    alt={worxDetails?.name}
                                    className={styles.bookingImage}
                                />
                                <div className={styles.bookingDetails}>
                                    <h3 className={styles.bookingDetailsTitle}>{space.name}</h3>
                                    <p className={styles.bookingDetailsText}>{`${space.city}, ${space.address}`}</p>
                                </div>
                            </div>
                            <div className={`${styles.bookingTimes} ${styles.borderTop} ${styles.pt2}`}>
                                <h4 className={styles.bookingTimesTitle}>Booking Times</h4>
                                <div>
                                    <p className={styles.timeTable}><span className={styles.fontSemibold}>Day:</span> {day.toLocaleDateString()}</p>
                                    <p className={styles.timeTable}><span className={styles.fontSemibold}>From:</span> {from_time}</p>
                                    <p className={styles.timeTable}><span className={styles.fontSemibold}>To:</span> {calculateToTime(from_time, numHours)}</p>
                                    <p className={styles.timeTable}><span className={styles.fontSemibold}>Seat Number:</span> {seatNumber}</p>
                                </div>
                            </div>
                            <div className={`${styles.priceDetails} ${styles.borderTop} ${styles.borderBottom} ${styles.py2} ${styles.my2}`}>
                                <h4 className={styles.priceDetailsTitle}>Price details</h4>
                                <div className={styles.priceDetailsText}>
                                    <span>{numHours} x hour{numHours > 1 && 's'}</span>
                                    <span>€{preTaxPrice.toFixed(2)}</span>
                                </div>
                                <div className={styles.priceDetailsText}>
                                    <span>Tax (23%)</span>
                                    <span>€{taxAmount.toFixed(2)}</span>
                                </div>
                                <div className={styles.priceDetailsText}>
                                    <span>Service fee</span>
                                    <span>€0</span>
                                </div>
                                {isFree ? (
                                    <div className={styles.priceDetailsText}>
                                        <span>Discount</span>
                                        <span>-€{discount.toFixed(2)}</span>
                                    </div>
                                ) : null}
                                <hr style={{ borderTop: '1px solid #ccc', margin: '10px 0' }} />
                                <div className={`${styles.priceDetailsText} ${styles.fontSemibold}`}>
                                    <span>Total (EUR)</span>
                                    <span>€{totalPrice.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.flexPayment}`}>
                            <div className={`${styles.paymentSection}`}>
                                {isLoading ? (
                                    <div className={`${styles.loaderContainer}`}>
                                        <ClipLoader size={50} color={"#123abc"} loading={isLoading} />
                                    </div>
                                ) : (
                                    stripePromise && clientSecret && (
                                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                                            <CheckoutForm
                                                space={space}
                                                worx={worx}
                                                booking={{
                                                    day,
                                                    numPeople,
                                                    price,
                                                    numHours,
                                                    from_time,
                                                    to_time: calculateToTime(from_time, numHours),
                                                    seatNumber
                                                }}
                                                isFree={isFree}
                                            />
                                        </Elements>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.locationSection} ${styles.mt4} ${styles.pt2} ${styles.borderTop}`}>
                        <h3 className={styles.locationTitle}>Location</h3>
                        <div className={styles.locationDetails}>
                            <p className={styles.locationDetailsText}>{space.name}</p>
                            <p className={styles.locationDetailsText}>{`${space.address}, ${space.eircode}`}</p>
                        </div>
                        <div className={styles.mapContainer}>
                            <WorxinnMap latitude={space.lat} longitude={space.lng} height="100%" marker={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorxBookingPage;
