import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FiEdit2 } from 'react-icons/fi';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

const EditURLComponent = ({ baseURL = "www.worxinn.com/", venueURLName, onSave }) => {
    const [urlPart, setUrlPart] = useState(venueURLName);
    const [prevUrlPart, setPrevUrlPart] = useState(venueURLName); // Stores the previous URL before editing
    const [isEditing, setIsEditing] = useState(false);
    const [validationError, setValidationError] = useState('');

    const tailWindEditIconStyle = "text-md text-black font-bold";

    const handleInputChange = (e) => {
        const value = e.target.value.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase().slice(0, 30); // Only allow letters, numbers, and hyphens, max 30 chars
        setUrlPart(value);

        // Validation logic
        if (value.length < 3 || value.length > 30) {
            setValidationError("URL must be between 3 and 30 characters.");
        } else if (!/^[a-z0-9-]*$/.test(value)) {
            setValidationError("Only lowercase letters, numbers, and hyphens are allowed.");
        } else {
            setValidationError(''); // Clear error if input is valid
        }
    };

    const handleSave = () => {
        if (validationError) return; // Do not proceed if there's a validation error
        setIsEditing(false);
        setPrevUrlPart(urlPart); // Update the previous URL to the new value after saving
        onSave(urlPart);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setUrlPart(prevUrlPart); // Reset to the previous URL value
        setValidationError(''); // Clear validation error on cancel
    };

    const handleCopy = () => {
        const fullUrl = `${baseURL}${urlPart}`;
        navigator.clipboard.writeText(fullUrl);
    };

    return (
        <div className="bg-white py-4 rounded-md w-full max-w-md">
            <h2 className="text-lg font-semibold mb-2 text-left">Edit Your Custom URL</h2>
            <p className="text-gray-500 mb-4">Personalize your URL for your venue page.</p>

            <div className="flex items-center border border-gray-300 rounded-md overflow-hidden">
                <span className="bg-gray-100 p-2 text-gray-600">{baseURL}</span>
                {isEditing ? (
                    <input
                        type="text"
                        value={urlPart}
                        onChange={handleInputChange}
                        className="flex-grow px-2 py-1 outline-none"
                    />
                ) : (
                    <span className="flex-grow px-2 py-1">{urlPart}</span>
                )}
            </div>

            {validationError && (
                <p className="text-red-500 text-sm mt-2">{validationError}</p>
            )}

            <p className="text-xs text-gray-400 mt-2">Note: Your custom URL should be between 3 and 30 characters. No spaces or special characters.</p>

            <div className="flex justify-end mt-4 space-x-2">
                {isEditing ? (
                    <>
                        <div
                            className="ml-2 mr-[17px] rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center cursor-pointer hover:bg-gray-300 text-lg"
                            onClick={handleCancel}
                        >
                            <CancelIcon fontSize="small" className="mr-1 opacity-[0.4] text-gray-600" />
                            <span className="opacity-[0.4] text-gray-600">Cancel</span>
                        </div>

                        <div
                            className={`ml-2 rounded-lg py-1 px-3 font-semibold flex flex-row items-center justify-center cursor-pointer text-lg ${validationError ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-400 hover:bg-orange-500'}`}
                            onClick={handleSave}
                        >
                            <SaveIcon fontSize="small" className="mr-1 opacity-[0.4] text-white" />
                            <span className="opacity-[0.4] text-white">Save</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="ml-2 mr-[17px] rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center cursor-pointer hover:bg-gray-300" onClick={() => setIsEditing(true)}>
                            <span className="opacity-[0.2] mr-1">
                                <FiEdit2 className={tailWindEditIconStyle} />
                            </span>
                            <div className="opacity-[0.4]">Edit</div>
                        </div>
                        <div
                            className="ml-2 rounded-lg bg-orange-400 py-1 px-3 font-semibold flex flex-row items-center justify-center cursor-pointer hover:bg-orange-500"
                            onClick={handleCopy}
                        >
                            <span className="opacity-[0.2] mr-1">
                                <ContentCopyIcon fontSize="small" />
                            </span>
                            <div className="opacity-[0.4]">Copy</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EditURLComponent;
