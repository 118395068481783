import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { userState } from '../State/userAtom';
import { UserAPIService } from '../Api/userAPIService';
import { uploadUserImageAndGetURL } from '../Api/firebaseService';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ReactComponent as RightArrow } from '../assets/icons/rightArrow.svg';
import { ReactComponent as PfpProfile } from '../assets/icons/pfpProfile.svg';
import { ReactComponent as EditIcon } from '../assets/icons/camera.svg';
import Header from './components/header';
import HoverEffectDiv from '../effects/HoverEffectDiv';
import styles from './editUserProfile.module.css';
import HoverEffectButton from '../effects/HoverRandomLetters';
import Footer from './components/footer';
import ResponsiveAppBar from './components/header/header_mui';

const EditUserProfile: React.FC = () => {
    const [user, setUser] = useRecoilState(userState);
    const [firstName, setFirstName] = useState<string>(user.bbdUser.user.firstName);
    const [lastName, setLastName] = useState<string>(user.bbdUser.user.lastName);
    const [email] = useState<string>(user.bbdUser.user.email);
    const [profilePicUrl, setProfilePicUrl] = useState<string>(user.bbdUser.user.profile_photo);
    const navigate = useNavigate();
    const userAPIService = new UserAPIService();

    useEffect(() => {
        // Redirect to login if not logged in
        if (!user || Object.keys(user).length === 0) {
            navigate('/login', { state: { isLogIn: true } });
        }
    }, [user, navigate]);

    const handleSaveProfile = async () => {
        const profileData = {
            firstName,
            lastName,
        };
        try {
            const response = await userAPIService.editUser(profileData, user.bbdUser.user.id);
            if (response.success) {
                alert('Profile updated successfully!');
                console.log(response.body);
                setUser({ ...user, bbdUser: { ...user.bbdUser, user: response.body } });
                navigate('/account');
            } else {
                console.error('Error updating profile:', response.message);
                alert(`Failed to update profile: ${response.message}`);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            alert(`Failed to update profile: ${error.message}`);
        }
    };

    const handleEditPhoto = async (source: CameraSource | string) => {
        try {
            let image;
            if (source === 'file') {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = async (event: any) => {
                    const file = event.target.files[0];
                    const uploadedImageUrl = await uploadUserImageAndGetURL(file, user.bbdUser.user.id);
                    if (uploadedImageUrl) {
                        setProfilePicUrl(uploadedImageUrl);
                        setUser({
                            ...user,
                            bbdUser: {
                                ...user.bbdUser,
                                user: {
                                    ...user.bbdUser.user,
                                    profile_photo: uploadedImageUrl
                                }
                            }
                        });
                        const profileData = {
                            "profile_photo": uploadedImageUrl
                        };

                        const response = await userAPIService.editUser(profileData, user.bbdUser.user.id);
                    }
                };
                input.click();
            } else {
                image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: false,
                    resultType: CameraResultType.Uri,
                    source: source as CameraSource
                });

                const response = await fetch(image.webPath);
                const blob = await response.blob();
                const file = new File([blob], "photo.png", { type: "image/png" });

                const uploadedImageUrl = await uploadUserImageAndGetURL(file, user.bbdUser.user.id);
                if (uploadedImageUrl) {
                    setProfilePicUrl(uploadedImageUrl);
                    setUser({
                        ...user,
                        bbdUser: {
                            ...user.bbdUser,
                            user: {
                                ...user.bbdUser.user,
                                profile_photo: uploadedImageUrl
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error taking photo or selecting from gallery:', error);
        }
    };

    const handleNavigateToEditProfile = () => {
        navigate('/edit-profile');
    };

    return (
        <div className={`${styles.bgGray100} min-h-screen flex flex-col`}>
            <ResponsiveAppBar />
            <div className={styles.container}>
                <header className={styles.userHeader}>
                    <div className={`${styles.userInfo} relative`}>
                        {user.bbdUser.user.profile_photo !== 'undefined' && user.bbdUser.user.profile_photo !== '' && user.bbdUser.user.profile_photo == 'empty' ? (
                            <div className={styles.profilePhotoContainer}>
                                <img
                                    src={profilePicUrl}
                                    alt="Profile"
                                    className={styles.profilePhoto}
                                />
                                <div className={styles.editPhotoIcon} onClick={() => handleEditPhoto(Capacitor.isNativePlatform() ? CameraSource.Prompt : 'file')}>
                                    <EditIcon />
                                </div>
                            </div>
                        ) : (
                            <div className={styles.profilePhotoPlaceholder}>
                                <PfpProfile />
                                <div className={styles.editPhotoIcon} onClick={() => handleEditPhoto(Capacitor.isNativePlatform() ? CameraSource.Prompt : 'file')}>
                                    <EditIcon />
                                </div>
                            </div>
                        )}
                        <div className={styles.userDetails}>
                            <div className="flex flex-row items-center cursor-pointer" onClick={() => { }}>
                                <h2 className={styles.profileName}>{firstName} {lastName}</h2>
                                <RightArrow className='mb-1' />
                            </div>
                            <HoverEffectDiv
                                onClick={handleNavigateToEditProfile}
                                text="Upgrade to pro"
                                isSelected={false} />
                        </div>
                    </div>
                </header>
                <div className={styles.profileForm}>
                    <div className={styles.formGroup}>
                        <input
                            type="text"
                            id="firstName"
                            className={styles.formInput}
                            placeholder={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <input
                            type="text"
                            id="lastName"
                            className={styles.formInput}
                            placeholder={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <input
                            type="password"
                            id="password"
                            placeholder='Password'
                            className={styles.formInput}
                        />
                    </div>
                    <div className={`${styles.formGroup} mb-5`}>
                        <input
                            type="password"
                            id="confirmPassword"
                            placeholder='Confirm Password'
                            className={styles.formInput}
                        />
                    </div>
                    <HoverEffectButton text="Search" onClick={handleSaveProfile} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EditUserProfile;
