import React, { useState, ChangeEvent, FormEvent } from 'react';
import styles from './FeedbackForm.module.css';
import { SERVER_URL } from '../Utils/constants';

interface FeedbackData {
    fullName?: string; // Optional full name
    review: string;
    rating: number;
    createdAt: string;
    updatedAt: string;
}

const FeedbackForm: React.FC = () => {
    const [fullName, setFullName] = useState<string>('');
    const [review, setReview] = useState<string>('');
    const [rating, setRating] = useState<number>(0);
    const [hoverRating, setHoverRating] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleFullNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFullName(e.target.value);
    };

    const handleReviewChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setReview(e.target.value);
    };

    const handleRatingClick = (ratingValue: number) => {
        setRating(ratingValue);
    };

    const handleMouseEnter = (ratingValue: number) => {
        setHoverRating(ratingValue);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        const feedbackData: FeedbackData = {
            fullName, // Include the full name if provided
            review,
            rating,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };

        try {
            const response = await fetch(`${SERVER_URL}review/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(feedbackData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit feedback');
            }

            setSuccessMessage('Thank you for your feedback!');
            setFullName(''); // Clear full name after submission
            setReview('');
            setRating(0);
        } catch (error) {
            setErrorMessage('There was a problem submitting your feedback. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className={styles.feedbackFormContainer}>
            <h2 className={styles.submitFeedbackText}>Submit Your Feedback</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label htmlFor="fullName">Full Name (Optional)</label>
                    <input
                        id="fullName"
                        type="text"
                        value={fullName}
                        onChange={handleFullNameChange}
                        className={styles.inputField}
                        maxLength={255}
                    />

                    <label>Rating</label>
                    <div className={styles.starRating}>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <span
                                key={star}
                                className={`${styles.star} ${star <= (hoverRating || rating) ? styles.starFilled : ''}`}
                                onClick={() => handleRatingClick(star)}
                                onMouseEnter={() => handleMouseEnter(star)}
                                onMouseLeave={handleMouseLeave}
                            >
                                &#9733;
                            </span>
                        ))}
                    </div>

                    <label htmlFor="review">Feedback</label>
                    <textarea
                        id="review"
                        value={review}
                        onChange={handleReviewChange}
                        className={styles.textarea}
                        required
                        maxLength={1024}
                    />
                </div>
                <button type="submit" className={styles.button} disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                </button>
            </form>
            {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        </div>
    );
};

export default FeedbackForm;
