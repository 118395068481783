import React, { useEffect, useState } from 'react';
import { UserAPIService } from '../../Api/userAPIService';
import WorxAPIService from '../../Api/worxAPIService';
import { ReactComponent as Clear } from '../../assets/icons/clear.svg';
import styles from './savedBookings.module.css';
import { userState } from '../../State/userAtom';
import { useRecoilState } from 'recoil';
import BottomNavBar from '../components/menu';
import { useNavigate } from 'react-router-dom';
import star from '../../assets/icons/star.svg';
import Header from '../components/header';
import HoverEffectDiv from '../../effects/HoverEffectDiv';
import Footer from '../components/footer';

const LikedItemsView: React.FC = () => {
    const [user] = useRecoilState(userState);
    const [likedItems, setLikedItems] = useState<any[]>([]);
    const navigate = useNavigate();
    const userAPIService = new UserAPIService();
    const worxAPIService = new WorxAPIService();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await userAPIService.getLikedWorxes(user.bbdUser.user.id);
                console.log(response);
                setLikedItems(response.body);
            } catch (error) {
                console.error('Error fetching liked worxes:', error);
            }
        };

        fetchData();
    }, [user.bbdUser.user.id]);

    const handleSpaceOnClick = (worx, e) => {
        e.stopPropagation();
        const today = new Date().toISOString().split('T')[0]; 
        const search = { day: today, numPeople: 1 };
        navigate(`/space/detail?spaceId=${worx.worxId}`, { state: { search } });
    }

    const handleUnlike = async (worxId: number, e) => {
        e.stopPropagation();
        try {
            await worxAPIService.unlikeWorx(user.bbdUser.user.id, worxId);
            setLikedItems(likedItems.filter(item => item.worxId !== worxId));
        } catch (error) {
            console.error('Error unliking worx:', error);
        }
    };

    return (
        <div className={`flex flex-col h-screen ${styles.bgGray100}`}>
            <Header>
                <HoverEffectDiv
                    text="Saved Spaces"
                    onClick={() => {}}
                    isSelected={true}
                />
            </Header>
            <div className={styles.container}>
                <div className={styles.itemsList}>
                    {likedItems.length > 0 ? (
                        likedItems.map((item, index) => (
                            <div key={index} className={styles.gridCard}>
                                <div className={styles.worxInfo}>
                                    <div className={styles.titleWorx} onClick={(e) => handleSpaceOnClick(item, e)}>
                                        {item.name}
                                    </div>
                                    <p className={styles.address}>
                                        {item.address}
                                    </p>
                                    <div className={styles.aminities}>
                                        {item.amenities && item.amenities[0] ? `${item.amenities[0].name}` : 'Amenetie not available'}
                                    </div>
                                    <div className={styles.gridPriceAndRating}>
                                        <div className={styles.rating}>
                                            <img src={star} alt="star" className="w-4 h-4" />
                                            {item.rating}
                                        </div>
                                        <div className={`${styles.price}`}>
                                            {item.price ? `${item.price}€/h` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.imageContainer}>
                                    <img 
                                        onClick={(e) => handleSpaceOnClick(item, e)} 
                                        src={item.photo_url ? item.photo_url : 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'} 
                                        alt={`Worx ${item.worxId}`} 
                                        className={styles.imgWorx} 
                                    />
                                    <div className={styles.clearIconContainer} onClick={(e) => handleUnlike(item.worxId, e)}>
                                        <Clear className={styles.clearIcon} />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='p-3'><p className='text-center text-gray-500'>No liked worxes found.</p></div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LikedItemsView;
