// WorkSpacesSection.tsx
import React from 'react';
import styles from './worxIframe.module.css';
import ButtonEffectHover from '../../effects/HoverEffectButton';

interface WorkSpacesSectionProps {
    workSpaces: any[];
    goToWorxDetail: (worxId: number) => void;
}

const WorkSpacesSection: React.FC<WorkSpacesSectionProps> = ({ workSpaces, goToWorxDetail }) => {
    return (
        <div>
            <h2 className={styles.spacesTitle}>Worx Spaces:</h2>
            <div className={styles.spacesList}>
                {workSpaces.length > 0 ? (
                    workSpaces.map(worx => (
                        <div key={worx.worx_id} className={styles.spaceItem} onClick={() => goToWorxDetail(worx.worx_id)}>
                            <div className={styles.spaceImage} style={{ backgroundImage: `url(${worx.worx_photo})` }}></div>
                            <h3 className={styles.spaceTitle}>{worx.worxspace_name}</h3>
                            <div className={styles.infoGrid}>
                                <div className={styles.flexColumn}>
                                    <ButtonEffectHover
                                        text="Book for Free"
                                        onClick={() => goToWorxDetail(worx.worx_id)}
                                        isSelected={false}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No worx spaces available</p>
                )}
            </div>
        </div>
    );
};

export default WorkSpacesSection;
