import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { userState } from '../../../State/userAtom';
import PersonIcon from '@mui/icons-material/Person';
import { useMediaQuery } from '@mui/material';
import { useRecoilState } from "recoil";
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../Api/firebaseService';

const pages = ['About', 'List your venue'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [user, setUser] = useRecoilState(userState);
    const [isLoggedIn, setIsLoggedIn] = useState(user && Object.keys(user).length !== 0);
    const [isVenueUser, setIsVenueUser] = useState(isLoggedIn && user && user?.bbdUser.user.UserTypeId === 2); // Assuming 'venue' role identified here
    const navigate = useNavigate();

    useEffect(() => {
        console.log('user changed')
        console.log(user && Object.keys(user).length !== 0)
        console.log(isLoggedIn && user?.bbdUser.user.UserTypeId === 2)
        setIsLoggedIn(user && Object.keys(user).length !== 0);
        setIsVenueUser(isLoggedIn && user?.bbdUser.user.UserTypeId === 2)
    }, [user]);

    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);

    const handleCloseNavMenu = () => setAnchorElNav(null);
    const handleCloseUserMenu = () => {
        setTimeout(() => setAnchorElUser(null), 0);
    };

    const handleLogIn = () => navigate('/login', { state: { isLogIn: true } });
    const handleRegister = () => navigate('/login', { state: { isLogIn: false } });

    const isMobile = useMediaQuery('(max-width:900px)');

    const handleLogout = () => {
        console.log('log out')
        handleCloseUserMenu();
        setIsLoggedIn(false)
        setIsVenueUser(false)
        setUser(null);
        logOut();
        navigate('/');
    };

    const handleLogoHomeClick = () => {
        navigate('/');
    }

    const handleMyBookingsClick = () => {
        handleCloseUserMenu();
        navigate('/my-bookings');
    }

    const handleMyProfile = () => {
        handleCloseUserMenu();
        navigate('/account');
    }

    const handleListVenue = () => {
        console.log("list your venue")
        handleCloseUserMenu();
        navigate('/partner-with-us');
    }

    const menuItems = isVenueUser
        ? [
            <MenuItem key="venue-management" onClick={handleCloseUserMenu}>Manage Venue</MenuItem>,
            <MenuItem key="venue-settings" onClick={handleCloseUserMenu}>Venue Settings</MenuItem>
        ]
        : [
            <MenuItem key="account" onClick={handleMyProfile}>Account</MenuItem>,
            <MenuItem key="bookings" onClick={handleMyBookingsClick}>My Bookings</MenuItem>
        ];

    return (
        <AppBar position="static" component="nav" sx={{ backgroundColor: '#e9e0e0', color: '#141414' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    {/* Logo for desktop */}
                    <Box sx={{
                        width: '10%',
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center',
                        mx: 'auto',
                        pl: 2,
                        pt: '10px',
                        pb: '10px',
                        cursor: 'pointer'
                    }}>
                        <img src="/logos/Logo_black.png" alt="Logo" style={{ width: '100%', height: 'auto' }} onClick={handleLogoHomeClick} />
                    </Box>

                    {/* Menu Icon for mobile */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'left' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {!isLoggedIn && (
                                <MenuItem key="list_venue" onClick={handleListVenue}>
                                    <Typography onClick={handleListVenue} sx={{ textAlign: 'center' }}>List your venue</Typography>
                                </MenuItem>
                            )}
                        </Menu>
                    </Box>

                    {/* Logo for mobile */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, justifyContent: 'center' }}>
                        <img src="/logos/Logo_black.png" alt="Logo" style={{ width: '100%', height: 'auto' }} />
                    </Box>

                    {/* Navigation buttons for desktop */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}>
                        {!isLoggedIn && (
                            <Button
                                key="list_venue"
                                onClick={handleListVenue}
                                sx={{
                                    my: 2,
                                    color: '#141414',
                                    mr: 3,
                                    padding: '6px 12px',
                                    border: '1px solid transparent',
                                    borderRadius: '8px',
                                    fontWeight: 500,
                                    '&:hover': {
                                        backgroundColor: '#f2f2f2',
                                        color: '#ff4500',
                                        borderColor: '#ff4500',
                                    },
                                    marginRight: '5em',
                                }}
                            >
                                List your venue
                            </Button>
                        )}
                    </Box>

                    {/* User Avatar */}
                    <Box sx={{ flexGrow: 0 }}>
                        {isLoggedIn ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid #818181',
                                    borderRadius: '24px',
                                    padding: '4px 8px',
                                    cursor: 'pointer'
                                }}
                                onClick={handleOpenUserMenu}
                            >
                                <IconButton sx={{ p: 0, mr: 1 }}>
                                    {isMobile ? (<PersonIcon />) : (<MenuIcon />)}
                                </IconButton>
                                <Avatar alt={user.bbdUser.user.firstName} src={user.bbdUser.user.profile_photo} />
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    open={Boolean(anchorElUser)}
                                    sx={{ mt: '45px' }}
                                    onClose={handleCloseUserMenu}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {menuItems}
                                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <>
                                <Button
                                    sx={{
                                        backgroundColor: '#ff4500',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        mr: 1,
                                        borderRadius: '5px',
                                        padding: '6px 16px',
                                        '&:hover': { backgroundColor: '#ff6a33' },
                                    }}
                                    onClick={handleRegister}
                                >
                                    Register
                                </Button>
                                <Button
                                    sx={{
                                        border: '1px solid #ff4500',
                                        color: '#ff4500',
                                        fontWeight: 'bold',
                                        borderRadius: '5px',
                                        padding: '6px 16px',
                                        '&:hover': {
                                            backgroundColor: '#ff4500',
                                            color: 'white',
                                        },
                                    }}
                                    onClick={handleLogIn}
                                >
                                    Log In
                                </Button>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
