import React, {
  useState,
  CSSProperties,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Textarea from "react-expanding-textarea";
import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineGlobal, AiOutlineMail } from "react-icons/ai";
import { useDebounce } from "../Hooks/usedDebounce";

interface Props {
  initialValue: string;
  tailwindTextStyle: string;
  onSave: (value: any) => void;
  currentSelectedEditableField: string;
  label: string;
  onSelect: (label: string) => void;
  onDeselect: (label: string) => void;
  isPhoneNumber?: boolean;
  isAddress?: boolean;
  isRate?: boolean;
  isEditable?: boolean;
  disableScaling?: boolean;
  numberOnly?: boolean;
}

const EditableTextField: React.FC<Props> = ({
  initialValue,
  onSave,
  tailwindTextStyle,
  currentSelectedEditableField,
  label,
  onSelect,
  onDeselect,
  isPhoneNumber = false,
  isAddress = false,
  isRate = false,
  isEditable = true,
  disableScaling = false,
  numberOnly = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const textareaRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [eircode, setEircode] = useState("");
  const [cost, setCost] = useState("");
  const [interval, setInterval] = useState("");

  const [hover, setHover] = useState(false);

  let isEditing = currentSelectedEditableField === label;

  const buttonStyle =
    "bg-white text-white rounded-full px-[30px] py-1 shadow-md";
  const buttonStyle2 =
    "bg-orange-500 text-white rounded-[10px] p-2 shadow-md mr-2";
  const tailWindIconStyle2 = "text-xl text-white font-bold";

  const backgroundOpacityStyle = isEditing
    ? "opacity-50 animate-fade-in pointer-events-auto"
    : "opacity-0 animate-fade-out pointer-events-none";

  const tailWindIconStyle = "text-xl text-black font-bold";
  const tailWindEditIconStyle = "text-md text-black font-bold";
  const textAreaStyle = `resize-none text-ellipsis w-full h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;

  const phoneTextAreaStyleS = `truncate resize-none text-ellipsis w-[20%] h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;
  const phoneTextAreaStyleL = `resize-none text-ellipsis w-[80%] h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;

  const addressTextAreaStyleS = `truncate resize-none text-ellipsis w-[25%] h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;
  const addressTextAreaStyleM = `resize-none text-ellipsis w-[25%] h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;
  const addressTextAreaStyleL = `resize-none text-ellipsis w-[25%] h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;
  const addressTextAreaStyleXL = `resize-none text-ellipsis w-[25%] h-full pl-[20px] pr-6 py-[4px] shadow-xl rounded-lg outline-auto over ${tailwindTextStyle}`;

  function extractIrishCountryCodeAndNumber(
    number: string
  ): [string, string] | [] {
    const irishNumberRegex = /^(353)(.*)$/;

    const match = number.match(irishNumberRegex);
    if (match) {
      const countryCode = `${match[1]}`;
      const restOfNumber = `${match[2]}`;

      const trimmedCountryCode = countryCode.replace(/\s/g, "");
      const trimmedRestOfNumber = restOfNumber.replace(/\s/g, "");

      return [trimmedCountryCode, trimmedRestOfNumber];
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (isAddress) {
      if (initialValue != undefined && initialValue != "") {
        let parts = initialValue.split(",");
        parts = parts.map((part) => part.trim());
        parts = parts.filter((part) => part != "");
        if (parts.length === 4) {
          setStreet(parts[0]);
          setCity(parts[1]);
          setCounty(parts[2]);
          setEircode(parts[3]);
        }
      }
    }

    if (isPhoneNumber) {
      if (initialValue != undefined && initialValue != "") {
        const parts = extractIrishCountryCodeAndNumber(value);
        if (parts.length === 2) {
          setCountryCode(parts[0]);
          setPhoneNumber(parts[1]);
        } else {
          setPhoneNumber(initialValue);
        }
      } else {
        setCountryCode("");
        setPhoneNumber("");
      }
    }

    if (isRate) {
      console.log("initial value")
      console.log(initialValue)
      console.log(initialValue != undefined && initialValue !== "")
      if (initialValue != undefined && initialValue !== "") {
        console.log("Entro set initial value rate")
        setCost(initialValue)
        setInterval("")
        //const parts = initialValue.split("/");
        //if (parts.length === 2) {
        //setCost(parts[0].replace(/\s/g, ""));
        //setInterval(parts[1].replace(/\s/g, ""));
        //}
      } else {
        setCost("10");
        setInterval("hour");
      }
    }
  }, [initialValue]);

  useEffect(() => {
    if (isPhoneNumber) {
      if (countryCode && phoneNumber) {
        setValue(`${countryCode} ${phoneNumber}`);
      } else {
        setValue(initialValue);
      }
    } else if (isAddress) {
      if (street && city && county && eircode) {
        let address = "";
        if (street && street != "") {
          address += street;
        }
        if (city && city != "") {
          address += `, ${city}`;
        }
        if (county && county != "") {
          address += `, ${county}`;
        }
        if (eircode && eircode != "") {
          address += `, ${eircode}`;
        }
        setValue(address);
      } else {
        setValue(initialValue);
      }
    } else if (isRate) {
      console.log(cost)
      console.log(initialValue)
      if (cost) {
        setValue(`${cost}`);
      } else {
        setValue(initialValue);
      }
    }
  }, [countryCode, phoneNumber, street, city, county, eircode, interval]);

  useEffect(() => {
    if (disableScaling) return;
    const handleResize = () => {
      debouncedHandleContainerWidth();
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const compareStringAndFindCharacterDifference = (
    string1: string,
    string2: string
  ) => {
    let difference = "";
    for (let i = 0; i < string1.length; i++) {
      if (string1[i] != string2[i]) {
        difference = string1[i];
        break;
      }
    }
    return difference;
  }

  const handleValueChange = (e: any) => {
    setValue(e);
  };

  const handleSave = () => {
    console.log("Handle save")
    const isText = !isPhoneNumber && !isAddress && !isRate;

    if (isPhoneNumber) {
      const number = {
        countryCode: countryCode,
        phoneNumber: phoneNumber,
      };
      onSave(number);
    }

    if (isRate) {
      const rate = {
        cost: cost,
        interval: interval,
      };

      onSave(cost);
    }

    if (isAddress) {
      const address = {
        street: street,
        city: city,
        county: county,
        eircode: eircode,
      };
      onSave(address);
    }

    if (isText) {
      console.log("Text save")
      const text = {
        text: value,
      };
      onSave(text);
    }

    onSelect("");
  };

  const transitionStyles: CSSProperties = {
    marginBottom: isEditing ? "60px" : "0px",
    minWidth: isEditing ? "240px" : "0px",
    transition: "margin-bottom 0.5s ease",
    paddingTop: isEditing ? "0px" : "0.25rem",
    paddingBottom: isEditing ? "0px" : "0.25rem",
  };

  const hoverStyle: CSSProperties =
    hover && isEditable
      ? {
        outlineStyle: "dashed",
        outlineOffset: "-2px",
        outlineWidth: isEditing ? "0px" : "2px",
        borderRadius: "8px",
        outlineColor: "rgba(0, 0, 0, 0.2)",
      }
      : {};

  const isURL = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const isEmail = (str: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(str);
  };

  const handleOpenLinkOrEmail = () => {
    if (isURL(value)) {
      window.open(value, "_blank");
    } else if (isEmail(value)) {
      window.location.href = `mailto:${value}`;
    }
  };

  const handleContainerWidth = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.style.width = "auto";
      const actualWidth = containerRef.current.offsetWidth;
      containerRef.current.style.width = `${actualWidth + 37}px`;
    }
  }, []);

  const debouncedHandleContainerWidth = useDebounce(handleContainerWidth, 1000);

  const handleFieldOnType = (e: any) => {
    const field = e.target.name; // Or use e.target.id
    let value = e.target.value;

    value = value.replace(/[^0-9]/g, '');

    if (field === "pet[countryCode]") {
      setCountryCode(value);
    } else if (field === "pet[phoneNumber]") {
      setPhoneNumber(value);
    }
  };


  const isRateField = () => {
    return (
      <div className="flex flex-row space-x-2">
        €
        <Textarea
          id="cost-textarea"
          name="pet[cost]"
          placeholder="Cost"
          ref={textareaRef}
          value={cost}
          onChange={(e) => {
            setCost(e.target.value)
            setValue(e.target.value)
          }}
          onClick={(e) => e.stopPropagation()}
          className={addressTextAreaStyleS}
        />
        / Hour
      </div>
    );
  };

  const isAddressField = () => {
    return (
      <div className="flex flex-row space-x-2">
        <Textarea
          id="street-textarea"
          name="pet[street]"
          placeholder="Street"
          ref={textareaRef}
          value={street}
          onChange={(e) => {
            setStreet(e.target.value);
          }}
          onClick={(e) => e.stopPropagation()}
          className={addressTextAreaStyleS}
        />
        <Textarea
          id="city-textarea"
          name="pet[city]"
          placeholder="City"
          ref={textareaRef}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          className={addressTextAreaStyleM}
        />
        <Textarea
          id="county-textarea"
          name="pet[county]"
          placeholder="County"
          ref={textareaRef}
          value={county}
          onChange={(e) => setCounty(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          className={addressTextAreaStyleL}
        />
        <Textarea
          id="eircode-textarea"
          name="pet[eircode]"
          placeholder="Eircode"
          ref={textareaRef}
          value={eircode}
          onChange={(e) => setEircode(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          className={addressTextAreaStyleXL}
        />
      </div>
    );
  };

  const isPhoneField = () => {
    return (
      <div className="flex flex-row space-x-2">
        <Textarea
          id="country-code-textarea"
          name="pet[countryCode]"
          onInput={e => handleFieldOnType(e)}
          placeholder="Country Code"
          ref={textareaRef}
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          className={phoneTextAreaStyleS}
        />
        <Textarea
          id="phone-number-textarea"
          name="pet[phoneNumber]"
          onInput={e => handleFieldOnType(e)}
          placeholder="Phone Number"
          ref={textareaRef}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          className={phoneTextAreaStyleL}
        />
      </div>
    );
  };

  const fieldControls = () => {
    return (
      <div
        className={`absolute left-0 mb-1 space-x-2 flex flex-row transform translate-y-[30%] ${isEditing ? "flex flex-col" : "hidden"
          }`}
      >
        <div className="flex flex-row space-x-2">
          <button className={buttonStyle} onClick={handleSave}>
            <BsCheckLg className={tailWindIconStyle} />
          </button>
          <button className={buttonStyle} onClick={() => onDeselect("")}>
            <RxCross2 className={tailWindIconStyle} />
          </button>
          {(isURL(value) || isEmail(value)) && (
            <button className={buttonStyle} onClick={handleOpenLinkOrEmail}>
              {isURL(value) ? (
                <AiOutlineGlobal className={tailWindIconStyle} />
              ) : (
                <AiOutlineMail className={tailWindIconStyle} />
              )}
            </button>
          )}
        </div>
      </div>
    );
  };

  const fieldDisplayState = () => {
    return (
      <div className="flex items-center ju pl-[20px]">
        {(isURL(value) || isEmail(value)) && (
          <button className={buttonStyle2} onClick={handleOpenLinkOrEmail}>
            {isURL(value) ? (
              <AiOutlineGlobal className={tailWindIconStyle2} />
            ) : (
              <AiOutlineMail className={tailWindIconStyle2} />
            )}
          </button>
        )}
        <div
          className={`whitespace-pre-line text-ellipsis max-w-[70vw] inline-block  pr-2 py-[4px] rounded-lg ${tailwindTextStyle}`}
        >
          {isURL(value) ? new URL(value).host : value}
        </div>
        <div className="ml-2 mr-[17px] rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center">
          <span className="opacity-[0.2] mr-1">
            {isEditable && <FiEdit2 className={tailWindEditIconStyle} />}
          </span>
          <div className="opacity-[0.4]">{"Edit"}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      id="editableText"
      className={`relative w-full ${isEditing ? "z-[100]" : "z-[2]"}`}
    >
      <div
        className={`${backgroundOpacityStyle} ${!isEditing ? (isEditable ? "pointer-events-none" : "") : ""
          } fixed inset-0 bg-black ${isEditable ? "cursor-pointer duration-[200ms]" : ""
          }`}
        onClick={(e) => {
          if (isEditing) {
            e.stopPropagation();
            onDeselect(label);
          }
        }}
      />
      <div
        className={`relative translate-x-[-20px] h-full w-full ${isEditing ? "" : isEditable ? "cursor-pointer" : ""
          }`}
        style={{ ...transitionStyles, ...hoverStyle }}
        onClick={(e) => {
          if (!isEditing && currentSelectedEditableField === "" && isEditable) {
            e.stopPropagation();
            onSelect(label);
          }
          if (
            isEditing &&
            currentSelectedEditableField === label &&
            isEditable
          ) {
            e.stopPropagation();
            onDeselect("");
          }
        }}
      >
        {isEditing ? (
          <div>
            {isPhoneNumber === true ? (
              isPhoneField()
            ) : isAddress === true ? (
              isAddressField()
            ) : isRate ? (
              isRateField()
            ) : (
              <Textarea
                id="notes-textarea"
                name="pet[notes]"
                ref={textareaRef}
                value={value}
                defaultValue={value}
                onChange={(e) => handleValueChange(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className={textAreaStyle}
              />
            )}
          </div>
        ) : (
          fieldDisplayState()
        )}
        {fieldControls()}
      </div>
    </div>
  );
};

export default EditableTextField;
