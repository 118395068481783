import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
};

function ListItem(props) {
    return (
        <Row className="border-b border-gray-300 py-2">
            <Col md={4}>{props.name}</Col> {/* Adjust the width using md={x} */}
            <Col md={3}>{props.bookingDate}</Col>
            <Col md={2}>{formatTime(props.from_time)}</Col>
            <Col md={2}>{formatTime(props.to_time)}</Col>
        </Row>
    );
}

function List(props) {
    // Sort the items by date in descending order
    const sortedItems = [...props.items].sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate));

    const items = sortedItems.map((item) => (
        <ListItem
            key={item.id}
            name={item.Worx.name}
            bookingDate={item.bookingDate}
            from_time={item.from_time}
            to_time={item.to_time}
        />
    ));

    return (
        <div>
            <Row className="border-b border-gray-300 py-2 font-bold">
                <Col md={4}>Space Name</Col>
                <Col md={3}>Date</Col>
                <Col md={2}>From</Col>
                <Col md={2}>To</Col>
            </Row>
            {items}
        </div>
    );
}

function BookingDashboardList(props) {
    return (
        <div>
            <h2 className="text-lg font-semibold mb-4">{props.title}</h2>
            <List items={props.bookings} />
        </div>
    );
}

export default BookingDashboardList;
