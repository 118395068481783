import 'core-js/stable';
import 'regenerator-runtime/runtime';

import "./App.css";
import MainApp from "./Components/MainApp.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { UserProvider } from "./Context/User/UserContext.js";
import Dashboard from "./Components/Dashboard/Dashboard.js";
import Worx from "./Components/Worx/Worx.js";
import SpecialDeals from "./Components/SpecialDeals/SpecialDeals.js";
import Bookings from "./Components/Bookings/Bookings.js";
import EditSpaceForm from "./Components/Space/EditSpace.js";
import CreateSpecialDeal from "./Components/SpecialDeals/CreateSpecialDeal.js";
import CreateWorx from "./Components/Worx/CreateWorx.js";
import EditWorx from "./Components/Worx/EditWorx.js";
import EditSpecialDeals from "./Components/SpecialDeals/EditSpecialDeals.js";
import SpaceHome from "./Components/Pages/spacehome.tsx";
import SpaceSelector from "./Components/spaceSelector.tsx";
import InviteHandlerPage from "./Invites/inviteHandlerPage.tsx";
import LogInScreen from "./User/home/login/login_user.tsx";
import UserProfile from "./User/userProfile/userProfile.tsx";
import SavedBookings from "./User/savedBookings/savedBookings.tsx";
import FeedbackForm from "./User/FeedbackForm.tsx";
import {
  RecoilRoot,
} from "recoil";
import Search from "./User/search.tsx";
import WorxDetailPage from "./User/worxDetail.tsx";
import WorxIframe from "./User/vendorPage/worxIframe.tsx";
import { loadStripe } from '@stripe/stripe-js';
import Completion from "./User/stripe/Completion.js";
import HomePage from "./User/home/home.tsx";
import WorxBookingPage from "./User/worxBookingPage.tsx";
import SpaceManagementPage from "./Invites/spaceContentManagement.tsx";
import SpaceCreation from "./Invites/createSpace.tsx";
import EditUserProfile from "./User/editUserProfile.tsx";
import RequestDeleteAccountPage from "./User/RequestDeletion.tsx";
import PrivacyPolicyPage from "./User/PrivacyPolicy.tsx";
import UserDashboard from "./User/bookings/myBookings.tsx";
import VenueDetailPage from "./User/VenueDetail.tsx";
import { SERVER_URL } from "./Utils/constants.js";
import { useLocation } from 'react-router-dom';
import { initGA, trackPage } from './analytics';
import WelcomingPage from "./User/welcomingPage.tsx";
import NotFound from "./Components/notFound.tsx";
import StorageLocker from "./User/storageLockerPdf.tsx";
import CookieBanner from "./User/CookieBanner.tsx";
import TechnicalProblemsPage from "./User/TechnicalProblemsPage.tsx";
import VenuePartner from "./User/VenuePartner.tsx";
import TestPage from './Components/test/testPage.tsx';
import LoginVenue from './User/home/login/login_venue.tsx';

function App() {

  const [stripePromise, setStripePromise] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true") {
      const trackingID = process.env.REACT_APP_GA_TRACKING_ID;
      if (trackingID) {
        initGA(trackingID);
      } else {
        console.error('Google Analytics tracking ID not found in environment variables');
      }
      trackPage(location.pathname + location.search);
    }
  }, [location]);

  useEffect(() => {
    fetch(`${SERVER_URL}stripe/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  return (
    <>
      <CookieBanner />
      <RecoilRoot>
        <UserProvider>
          <Routes>

            {/*<Route path="*" element={<TechnicalProblemsPage />} />*/}
            <Route path="/login" element={<LogInScreen />} />
            <Route path="/spaces" element={<SpaceSelector />} />
            {/*<Route path="/" element={<SpaceSelector />} />*/}
            <Route path="/space" element={<SpaceHome />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/worx" element={<Worx />} />
            <Route path="/specialdeals" element={<SpecialDeals />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/space/edit" element={<EditSpaceForm />} />
            <Route
              path="/specialdeals/create"
              element={<CreateSpecialDeal />}
            />
            <Route
              path="/join/:spaceId/:inviteId"
              element={<InviteHandlerPage />}
            />
            <Route path="/space/create" element={<SpaceCreation />} />
            <Route path="/worx/create" element={<CreateWorx />} />
            <Route path="/worx/edit/:id" element={<EditWorx />} />
            <Route
              path="/specialdeals/edit/:id"
              element={<EditSpecialDeals />}
            />
            <Route path="/worx/iframe/:id" element={<WorxIframe />} />
            <Route path="venue/:venueName" element={<WorxIframe />} />
            <Route path="/corkinternational/:id" element={<WorxIframe />} />
            <Route path="/welcome" element={<WelcomingPage />} />
            <Route path="/search" element={<Search />} />
            <Route path="/space/detail" element={< WorxDetailPage stripePromise={stripePromise} />} />
            <Route path="/completion" element={<Completion stripePromise={stripePromise} />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/space/book" element={<WorxBookingPage />} />
            <Route path="/account" element={<UserProfile />} />
            <Route path="/edit-profile" element={<EditUserProfile />} />
            <Route path="/request-deletion" element={<RequestDeleteAccountPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/my-bookings" element={<UserDashboard />} />
            <Route path="/venue/detail" element={<VenueDetailPage />} />
            <Route path="/saved-bookings" element={<SavedBookings />} />
            <Route path="/storage-locker" element={<StorageLocker />} />
            <Route path="/feedback" element={<FeedbackForm />} />
            <Route path="/hotels" element={<VenuePartner />} />
            {/*<Route path="/test" element={<TestPage />} />*/}
            <Route path="/venue/login" element={<LoginVenue />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </UserProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
