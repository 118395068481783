// MeetingRoomsSection.tsx
import React from 'react';
import styles from './worxIframe.module.css';
import ButtonEffectHover from '../../effects/HoverEffectButton';

interface MeetingRoomsSectionProps {
    meetingRooms: any[];
    goToWorxDetail: (worxId: number) => void;
}

const MeetingRoomsSection: React.FC<MeetingRoomsSectionProps> = ({ meetingRooms, goToWorxDetail }) => {
    return (
        <div>
            <h2 className={styles.spacesTitle}>Meeting Rooms:</h2>
            <div className={styles.spacesList}>
                {meetingRooms.length > 0 ? (
                    meetingRooms.map(worx => (
                        <div key={worx.worx_id} className={styles.spaceItem} onClick={() => goToWorxDetail(worx.worx_id)}>
                            <div className={styles.spaceImage} style={{ backgroundImage: `url(${worx.worx_photo})` }}></div>
                            <h3 className={styles.spaceTitle}>{worx.worxspace_name}</h3>
                            <div className={styles.infoGrid}>
                                <div className={styles.flexRow}>
                                    <div className={styles.flexP}>
                                        <p>Capacity:</p>
                                        <p>{worx.worxspace_capacity}</p>
                                    </div>
                                </div>
                                <div className={styles.flexColumn}>
                                    <ButtonEffectHover
                                        text="Book for Free"
                                        onClick={() => goToWorxDetail(worx.worx_id)}
                                        isSelected={false}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No meeting rooms available</p>
                )}
            </div>
        </div>
    );
};

export default MeetingRoomsSection;
