import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../State/userAtom';
import { UserAPIService } from '../../Api/userAPIService';
import { useNavigate } from 'react-router-dom';
import BottomNavBar from '../components/menu';
import { ReactComponent as Help } from '../../assets/icons/lifebuoy.svg';
import { ReactComponent as Suggestions } from '../../assets/icons/like-dislike.svg';
import { ReactComponent as Legal } from '../../assets/icons/receipt2.svg';
import { ReactComponent as Terms } from '../../assets/icons/receipt.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/rightArrow.svg';
import { ReactComponent as PfpProfile } from '../../assets/icons/pfpProfile.svg';
import WorxAPIService from '../../Api/worxAPIService';
import styles from './userProfile.module.css';
import { logOut } from '../../Api/firebaseService';
import Header from '../components/header';
import HoverEffectDiv from '../../effects/HoverEffectDiv';
import HoverEffectButton from '../../effects/HoverRandomLetters';
import Footer from '../components/footer';
import ResponsiveAppBar from '../components/header/header_mui';

const userAPIService = new UserAPIService();
const worxApiService = new WorxAPIService();

const UserProfile: React.FC = () => {
    const [user, setUser] = useRecoilState(userState);
    const [bookings, setBookings] = useState([]);
    const [savedSpaces, setSavedSpaces] = useState([]);
    const [savedWorxes, setSavedWorxes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to login if not logged in
        if (!user || Object.keys(user).length === 0) {
            navigate('/login', { state: { isLogIn: true } });
        }
    }, [user, navigate]);

    useEffect(() => {
        const fetchBookings = async () => {
            const response = await userAPIService.getFutureBookings(user.bbdUser.user.id);
            setBookings(response.body);
        };

        const fetchSavedSpaces = async () => {
            const response = await userAPIService.getLikedSpaces(user.bbdUser.user.id);
            setSavedSpaces(response.body);
        };

        const fetchSavedWorxes = async () => {
            try {
                const response = await userAPIService.getLikedWorxes(user.bbdUser.user.id);
                if (response.body) {
                    setSavedWorxes(response.body);
                } else {
                    setSavedWorxes([]);
                }
            } catch (error) {
                console.error("Error fetching saved worxes:", error);
                setSavedWorxes([]);
            }
        };

        fetchBookings();
        fetchSavedSpaces();
        fetchSavedWorxes();
    }, [user]);

    const handleNavigateToEditProfile = () => {
        navigate('/edit-profile');
    };

    const handleNavigateToMyBookings = () => {
        navigate('/my-bookings');
    };

    const handleSaveWorx = async (worxId) => {
        try {
            const userId = user.bbdUser.user.id;
            await worxApiService.saveWorx(userId, worxId);
        } catch (error) {
            console.error("Error saving worx:", error);
        }
    };

    const handleLogout = () => {
        setShowModal(false);
        setUser(null);
        logOut();
        navigate('/');
    };

    const renderPhotos = (items) => (
        <div className={styles.photoGrid}>
            {items.length > 0 && items.slice(0, 5).map((item, index) => (
                <img
                    key={index}
                    src={item.photoUrls ? item.photoUrls[0] : item.photo_url}
                    alt={item.spaceId ? item.spaceId : item.worxId}
                    className={styles.photoThumbnail}
                />
            ))}
        </div>
    );

    const handlePrivacyOnClick = () => {
        navigate('/privacy-policy')
    }

    return (
        <div className={`flex flex-col max-h-max ${styles.bgGray100}`}>
            <ResponsiveAppBar />
            <div className={styles.container}>
                <header className={styles.userHeader}>
                    <div className={styles.userInfo}>
                        {user.bbdUser.user.profile_photo !== 'undefined' && user.bbdUser.user.profile_photo !== '' && user.bbdUser.user.profile_photo == 'empty' ? (
                            <img
                                src={user.bbdUser.user.profile_photo}
                                alt="Profile"
                                className={styles.profilePhoto}
                            />
                        ) : (
                            <div className="relative w-[99.363px] h-[100px] flex-shrink-0 bg-gradient-to-b from-[#DEEFFF] to-[#BCDFFF] rounded-full flex items-center justify-center mx-auto mb-3">
                                <PfpProfile />
                            </div>
                        )}
                        <div className={styles.userDetails}>
                            <div className="flex flex-row items-center cursor-pointer" onClick={handleNavigateToEditProfile}>
                                <h2 className={styles.name}>{user.bbdUser.user.firstName} {user.bbdUser.user.lastName}</h2>
                                <RightArrow className='mb-1' />
                            </div>
                        </div>
                    </div>

                    {/* New Profile and Edit Profile Buttons Section */}
                    <div className="flex justify-center mt-4 space-x-4">
                        <HoverEffectDiv
                            text="Edit Profile"
                            onClick={handleNavigateToEditProfile}
                            isSelected={false}
                        />
                    </div>
                </header>
                <div className={styles.bookingsSavedSection}>
                    <div className={styles.bookingsSection}>
                        <div className={styles.bookings}>
                            <HoverEffectDiv text="Go to my bookings" onClick={handleNavigateToMyBookings} isSelected={false} />
                        </div>
                        <div className='flex'>
                            <div className='flex flex-col mr-4'>
                                <h3 className={styles.name}>Bookings</h3>
                                <div className={`${styles.bookingsCount} font-bold`}>{bookings.length}</div>
                                <div>
                                    {renderPhotos(bookings)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.userOptions}>
                    <ul>
                        <li className={styles.tos}> <Help /> Support</li>
                        <li className={styles.tos}> <Suggestions /> Suggestions</li>
                        <li className={styles.tos} onClick={handlePrivacyOnClick}> <Legal /> Privacy Policy</li>
                        <li className={styles.tos}> <Terms /> Terms and conditions</li>
                        <li className={`${styles.logout} mt-3 underline decoration-1 underline-offset-1`} onClick={() => setShowModal(true)}> Log out</li>
                    </ul>
                </div>
                {showModal && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <h2>Confirm Logout</h2>
                            <p>Are you sure you want to log out?</p>
                            <div className={styles.modalActions}>
                                <button onClick={() => setShowModal(false)} className={styles.cancelButton}>Cancel</button>
                                <button onClick={handleLogout} className={styles.logoutButton}>Log Out</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default UserProfile;
