import React from 'react';
import PdfViewer from './components/pdfViewer';


function StorageLocker() {

    const pdf = 'pdf/Storage_Drawer.pdf';

    console.log(pdf)
    return (
        <div style={{backgroundColor: '#323639'}}>
            <PdfViewer pdf={pdf} />
        </div>
    );
}

export default StorageLocker;