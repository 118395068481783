import React, { useEffect } from 'react';
import styles from './welcomingPage.module.css';
import googlePlayImage from '../assets/externalSites/googlePlay.png';
import appStoreImage from '../assets/externalSites/appStore.png';
import logo from '../assets/externalSites/Logo_white.png';
import linkedinIcon from '../assets/externalSites/linkedin.png';  // Add LinkedIn icon
import instagramIcon from '../assets/externalSites/instagram.png';  // Add Instagram icon
import ButtonEffectHover from '../effects/HoverEffectButton';
import Footer from './components/footer';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import { trackPage, trackEvent } from '../analytics';

const WelcomingPage: React.FC = () => {

  useEffect(() => {
    trackPage('/welcoming'); // Tracks page view
  }, []);

  const handleButtonClick = (buttonName) => {
    trackEvent('Button Click', 'Click' ,buttonName); // Tracking button clicks
  };

  const handleRedirectWithDelay = (url, buttonName) => {
    handleButtonClick(buttonName);  // Fire the analytics event
    setTimeout(() => {
      window.location.href = url;  // Redirect after a short delay
    }, 300);  // Adjust the delay time as needed (300 ms is usually sufficient)
  };

  return (
    <div className={styles.welcomingPage}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <img
            src={logo}
            alt="WorXInn Logo"
            className={styles.logo}
            onClick={() => handleRedirectWithDelay('/', 'Logo Click')}
          />
          <h1>Working Made Easy</h1>
          <p>Welcome to WorXInn.</p>
          <p>
            You have landed here because you have been invited to be an early adopter and offered free Premium Membership for life.
          </p>
          <p>Choose any of the links below or the logo to check out WorXInn in Beta and register/download.</p>

          <div className={styles.cardLinks}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                handleRedirectWithDelay('https://play.google.com/store/apps/details?id=app.run.a.worxinnweb_ej6tzvcmqa_ew.twa&pli=1', 'Google Play Link Click');
              }}
            >
              <img src={googlePlayImage} alt="Get it on Google Play" className={styles.cardLinkImage} />
            </a>

            <a
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                handleRedirectWithDelay('https://apps.apple.com/us/app/worxinn/id6502445615', 'App Store Link Click');
              }}
            >
              <img src={appStoreImage} alt="Download on the App Store" className={styles.cardLinkImage} />
            </a>

            <ButtonEffectHover
              text={`Continue to web`}
              onClick={() => {
                handleRedirectWithDelay('/', 'Web Click');
              }}
              isSelected={false}
            />
          </div>

          <div className={styles.socialMediaLinks}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                handleRedirectWithDelay('https://www.linkedin.com/company/plus10-coworking-and-innovation-space/', 'LinkedIn Click');
              }}
            >
              <FaLinkedin size={40} />
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                handleRedirectWithDelay('https://www.instagram.com/worxinn/', 'Instagram Click');
              }}
            >
              <FaInstagram size={40} />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WelcomingPage;
