import React from 'react';
import styles from './card.module.css';

interface CardProps {
  title: string;
  subtitle?: string[];
  iconClass: string;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ title, subtitle, iconClass, children }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        {subtitle && (
          <h4 className={styles.cardSubtitle}>
            {subtitle.map((line, index) => (
              <span key={index} className={styles.cardSubtitleLine}>{line}</span>
            ))}
          </h4>
        )}
        {children}
      </div>
      <i className={`${iconClass} ${styles.cardIcon}`}></i>
    </div>
  );
};

export default Card;
