import React, { useEffect, useRef, useState } from 'react'
import { motion, useScroll, useTransform, useSpring, AnimatePresence } from 'framer-motion'
import { ArrowRightIcon, CheckIcon, LightningBoltIcon, PersonIcon, RocketIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom';

const handleSignIn = (navigate) => {
    navigate('/login');
};

const images = [
    '/img/worx4.jpg',
    '/img/worx5.jpg',
    '/img/worx6.jpg',
]

const marketingText = [
    'Add Workspace as an Amenity',
    'Boost Direct and Ancillary Revenue with Workspace as an Amenity',
    'Elevate Your Hotel\'s Appeal to the Modern Professional',
]

const stats = [
    { value: '15%', label: 'Average Revenue Increase' },
    { value: '25%', label: 'Extended Stay Bookings' },
    { value: '4.6/5', label: 'Workspace Satisfaction' },
]

export default function Component() {
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hoveredStates, setHoveredStates] = useState<{ [key: number]: boolean }>({});

    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ['start start', 'end start'],
    });

    const smoothProgress = useSpring(scrollYProgress, { stiffness: 100, damping: 30, restDelta: 0.001 });

    useEffect(() => {
        const unsubscribe = smoothProgress.onChange((latest) => {
            const progress = latest * (images.length - 1);
            const index = progress < images.length - 1 ? Math.round(progress) : images.length - 1;
            setCurrentIndex(index);
        });
        return () => unsubscribe();
    }, [smoothProgress]);

    const scale = useTransform(smoothProgress, [0, 1], [1, 1.2]);

    return (
        <div className="relative h-[400vh]" ref={containerRef}>
            <div className="sticky top-0 h-screen overflow-hidden">
                {images.map((src, index) => (
                    <motion.div
                        key={src}
                        className="absolute inset-0 flex items-center justify-center"
                        style={{ scale }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: currentIndex === index ? 1 : 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={src}
                            alt={`Slide ${index + 1}`}
                            className="object-cover w-full h-full"
                        />
                        <motion.div
                            className="absolute inset-0 flex flex-col items-center justify-center text-white text-center p-4 bg-black bg-opacity-50"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: currentIndex === index ? 1 : 0, y: currentIndex === index ? 0 : 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-4xl font-bold mb-4" style={{ fontFamily: 'IBMPlexMono, monospace' }}>
                                {marketingText[index]}
                            </h2>
                            {index === images.length - 1 && (
                                <>
                                    <p className="text-xl mb-8" style={{ fontFamily: 'IBMPlexMonoLight, monospace' }}>Partner with WorXInn to transform your hotel spaces</p>
                                    <div className="relative inline-block" style={{ fontFamily: 'IBMPlexMono, monospace' }}>
                                        <motion.button
                                            className="bg-orange-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-orange-600 transition-colors duration-300"
                                            style={{ pointerEvents: currentIndex === index ? 'auto' : 'none' }}
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onMouseEnter={() => setHoveredStates(prev => ({ ...prev, [index]: true }))}
                                            onMouseLeave={() => setHoveredStates(prev => ({ ...prev, [index]: false }))}
                                            onClick={() => handleSignIn(navigate)}
                                        >
                                            Become a Partner
                                        </motion.button>
                                        <AnimatePresence>
                                            {hoveredStates[index] && (
                                                <motion.div
                                                    className="mt-4 text-sm"
                                                    initial={{ opacity: 0, y: 10 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    exit={{ opacity: 0, y: 10 }}
                                                >
                                                    Click to start your journey with WorXInn
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                </>
                            )}
                        </motion.div>
                    </motion.div>
                ))}
                <div className="absolute bottom-0 left-0 right-0 flex justify-center space-x-16 p-8 bg-white bg-opacity-80" style={{ fontFamily: 'IBMPlexMonoLight, monospace' }}>
                    {stats.map((stat, index) => (
                        <motion.div
                            key={index}
                            className="text-center"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.2 }}
                        >
                            <div className="text-3xl font-bold text-orange-500">{stat.value}</div>
                            <div className="text-sm text-gray-600">{stat.label}</div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    )
}
