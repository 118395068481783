import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Helper function to convert a string to a Date object
function convertTimeStringToDate(timeString: string | undefined | null): Date | undefined {
    if (!timeString) return;

    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Set seconds and milliseconds to 0
    return date;
}

// Helper function to convert a Date object to a time string
function convertDateToTimeString(date: Date | null): string | undefined {
    if (!date) return;

    return `${date.getHours()}:${date.getMinutes()}:00`;
}
interface Availability {
    [key: string]: {
        open: string | null;  // Time strings in the "HH:MM:SS" format
        close: string | null; // Time strings in the "HH:MM:SS" format
        isSelected: boolean;
    };
}

interface OpeningClosingTimePickerProps {
    initialOpeningTime?: string | null;
    initialClosingTime?: string | null;
    availability?: Availability;
    onDaySelectionChange: (day: string, isSelected: boolean) => void;
    onOpeningTimeChange: (day: string, time: string | undefined) => void;
    onClosingTimeChange: (day: string, time: string | undefined) => void;
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const OpeningClosingTimePicker: React.FC<OpeningClosingTimePickerProps> = ({
    initialOpeningTime,
    initialClosingTime,
    availability,
    onDaySelectionChange,
    onOpeningTimeChange,
    onClosingTimeChange,
}) => {
    const [selectedDays, setSelectedDays] = useState({});
    const [openingTimes, setOpeningTimes] = useState({});
    const [closingTimes, setClosingTimes] = useState({});
    const [globalOpeningTime, setGlobalOpeningTime] = useState(null);
    const [globalClosingTime, setGlobalClosingTime] = useState(null);

    useEffect(() => {
        if (availability) {
            daysOfWeek.forEach(day => {
                const openKey = `opening_time_${day.toLowerCase()}`;
                const closeKey = `closing_time_${day.toLowerCase()}`;

                setSelectedDays(prev => ({
                    ...prev,
                    [day]: availability[openKey] !== null && availability[closeKey] !== null
                }));

                setOpeningTimes(prev => ({
                    ...prev,
                    [day]: availability[openKey] ? new Date(`1970-01-01T${availability[openKey]}Z`) : null
                }));

                setClosingTimes(prev => ({
                    ...prev,
                    [day]: availability[closeKey] ? new Date(`1970-01-01T${availability[closeKey]}Z`) : null
                }));
            });
        }
    }, [availability]);

    const applyAllTimes = () => {
        const newOpeningTime = convertDateToTimeString(globalOpeningTime);
        const newClosingTime = convertDateToTimeString(globalClosingTime);

        daysOfWeek.forEach(day => {
            onOpeningTimeChange(day, newOpeningTime);
            onClosingTimeChange(day, newClosingTime);
            onDaySelectionChange(day, true);
        });

        const newOpeningTimes = daysOfWeek.reduce((acc, day) => ({
            ...acc,
            [day]: globalOpeningTime
        }), {});
        const newClosingTimes = daysOfWeek.reduce((acc, day) => ({
            ...acc,
            [day]: globalClosingTime
        }), {});
        setOpeningTimes(newOpeningTimes);
        setClosingTimes(newClosingTimes);
        setSelectedDays(daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: true }), {}));
    };

    const handleDayCheckbox = (day: string) => {
        const isSelected = !selectedDays[day];
        setSelectedDays({ ...selectedDays, [day]: isSelected });
        onDaySelectionChange(day, isSelected);
    };

    const handleOpeningTime = (day: string, time: Date | null) => {
        setOpeningTimes({ ...openingTimes, [day]: time });
        onOpeningTimeChange(day, convertDateToTimeString(time));
    };

    const handleClosingTime = (day: string, time: Date | null) => {
        setClosingTimes({ ...closingTimes, [day]: time });
        onClosingTimeChange(day, convertDateToTimeString(time));
    };

    return (
        <div className="my-4">
            <div className="flex flex-col items-center mb-4">
                <DatePicker
                    selected={globalOpeningTime}
                    onChange={setGlobalOpeningTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Open Time"
                    dateFormat="h:mm aa"
                    className="p-2 border border-gray-300 rounded-md mb-2"  // Added mb-2 for margin-bottom
                />
                <DatePicker
                    selected={globalClosingTime}
                    onChange={setGlobalClosingTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Close Time"
                    dateFormat="h:mm aa"
                    className="p-2 border border-gray-300 rounded-md mb-2"  // Added mb-2 for margin-bottom
                />
                <button
                    onClick={applyAllTimes}
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md"
                >
                    Set All Days
                </button>
            </div>
            {daysOfWeek.map(day => (
                <div key={day} className="flex items-center mb-2 text-sm">
                    <input
                        type="checkbox"
                        id={`checkbox-${day}`}
                        checked={selectedDays[day]}
                        onChange={() => handleDayCheckbox(day)}
                        className="mr-2 w-4 h-4" // Smaller checkbox
                    />
                    <label htmlFor={`checkbox-${day}`} className="flex-none mr-2 w-24 ">{day}</label>
                    <DatePicker
                        selected={openingTimes[day]}
                        onChange={(time) => handleOpeningTime(day, time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        disabled={!selectedDays[day]}  // Disable if day is not selected
                        className={`flex-none w-24 p-2 border ${!selectedDays[day] ? 'border-gray-400 bg-gray-100 text-gray-500 cursor-not-allowed' : 'border-gray-300 focus:outline-none focus:border-blue-500'}`}
                    />
                    <span className="mx-2">to</span>
                    <DatePicker
                        selected={closingTimes[day]}
                        onChange={(time) => handleClosingTime(day, time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        disabled={!selectedDays[day]}  // Disable if day is not selected
                        className={`flex-none w-24 p-2 border ${!selectedDays[day] ? 'border-gray-400 bg-gray-100 text-gray-500 cursor-not-allowed' : 'border-gray-300 focus:outline-none focus:border-blue-500'}`}
                    />
                </div>
            ))}
        </div>
    );
};

export default OpeningClosingTimePicker;
