import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userState } from "../State/userAtom";
import { SpaceDetail } from "../Models/spaceDetails";
import { useAsync } from "../Hooks/useAsync";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useRecoilState } from "recoil";
import { SpaceAPIService } from "../Api/spaceAPIService";
import { HotelPhoto } from "../Models/hotelPhoto";
import { writeUser } from "../Api/firebaseService";
import User from "../Models/adminUser";
import { MdOutlineCreate } from "react-icons/md";
import WorxAPIService from "../Api/worxAPIService";
import { WorxDetail } from "../Models/worxDetails";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import SpaceDisplaySlider from "./spaceDisplaySlider";
import { SERVER_URL } from "../Utils/constants";

type SpaceDisplayType = {
  isEditable: boolean;
  currentSpaceId: number;
  space: SpaceDetail;
  parentImage?: string;
  typeOfSpaceId: number;
  typeOfSpaceString: string;
};

const LoadingScreen = () => (
  <div className="w-full h-[17vh] flex items-center justify-center text-2xl">
    <AiOutlineLoading3Quarters className="animate-spin" />
  </div>
);

const SpaceDisplay: React.FC<SpaceDisplayType> = ({
  space,
  isEditable,
  currentSpaceId,
  parentImage = "https://www.pngkey.com/png/detail/470-4703342_generic-placeholder-image-conference-room-free-icon.png",
  typeOfSpaceId,
  typeOfSpaceString,
}) => {
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const worxAPIService = new WorxAPIService();
  const placeholder = parentImage;
  const nav = useNavigate();

  const handleCreateWorx = async () => {
    if (!isEditable) return;

    const capacity = typeOfSpaceId === 2 ? 1 : 0
    const worx: WorxDetail = {
      id: 0,
      name: "",
      description: "",
      type: null,
      price: 0,
      isVisible: null,
      main_photo: "",
      capacity: capacity,
      rate: "",
      email: "",
      phone: "",
      totalReviews: 0,
      rating: 0,
      createdAt: new Date().toDateString(),
      updatedAt: new Date().toDateString(),
      SpaceId: currentSpaceId,
      WorxTypeId: typeOfSpaceId,
      createdById: null,
      WorxPhotos: [],
      Amenities: [],
      numberOfUnits: 1
    };

    console.log(currentUser)
    const currentUserPermissionLevel = currentUser.bbdUser.user.Space[0] == space.id || currentUser.bbdUser.Space[0] == space.id ? 1 : 0;

    if (currentUserPermissionLevel === false) {
      return;
    }

    const respCreateWorx = await worxAPIService.createWorx(worx);
    const id = respCreateWorx.body.id;

    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30';

    const responseAvailability = await fetch(`${SERVER_URL}worx-availability/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Add authorization header
        },
        body: JSON.stringify({
            WorxId: id,
            opening_time_monday: null,
            closing_time_monday: null,
            opening_time_tuesday: null,
            closing_time_tuesday: null,
            opening_time_wednesday: null,
            closing_time_wednesday: null,
            opening_time_thursday: null,
            closing_time_thursday: null,
            opening_time_friday: null,
            closing_time_friday: null,
            opening_time_saturday: null,
            closing_time_saturday: null,
            opening_time_sunday: null,
            closing_time_sunday: null
        })
    });

    const updatedUser = {
      ...currentUser,
      spaces: { ...currentUser.spaces, ["" + id]: currentUserPermissionLevel },
    } as User;

    writeUser(updatedUser);
    setCurrentUser(updatedUser);
    setTimeout(() => {
      //space?id=40&isworx=1&pid=34
      console.log(typeOfSpaceId)
      if (typeOfSpaceId === 1) {
        nav(`/space?id=${id}&ismeet=1&pid=${space.id}`, { replace: false });
      } else if(typeOfSpaceId === 2) {
        nav(`/space?id=${id}&isworx=1&pid=${space.id}`, { replace: false });
      }
      
      window.location.reload();
    }, 50);
  };


  const worxes = space.Worxes.filter(e => e.WorxTypeId === typeOfSpaceId);

  return (
    <div className="flex flex-col items-start translate-x-[-8px]">
      {worxes.length !== 0 && (
        <SpaceDisplaySlider
          worxes={worxes}
          placeholder={placeholder}
          currentSpaceId={currentSpaceId + ""}
          typeOfSpaceId={typeOfSpaceId}
        />
      )}

      <div
        onClick={handleCreateWorx}
        className="outline translate-x-[8px] bg-orange-500 rounded-xl text-[20xp] h-[60px] outline-2 py-2 px-10 text-white text-center max-h-[100px] outline-orange-400/[15%] flex justify-center items-center font-bold mt-2 overflow-hidden hover:scale-[1.01] transition-all duration-400 object-cover cursor-pointer hover:outline-dashed hover:outline-[3px] hover:outline-orange-400/50 hover hover:outline-offset-2 shadow-md hover:shadow-none"
      >
        <MdOutlineCreate className="mr-2 text-2xl opacity-80" />
        {`Create ${typeOfSpaceString}`}
      </div>
    </div>
  );
};

export default SpaceDisplay;
