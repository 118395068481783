import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { logOut } from "../Api/firebaseService";
import { useRecoilState } from "recoil";
import { userState } from '../State/userAtom';

interface UserIconDrawerProps {
  initials: string;
}

const UserIconDrawer: React.FC<UserIconDrawerProps> = ({ initials }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
    navigate("/");
    logOut();
  };

  const isIntialsQuestionMarks = initials === "??" ? true : false;

  if (isIntialsQuestionMarks) {
    return;
  }

  return (
    <div className="relative cursor-pointer rounded-full flex flex-row items-center justify-center">
      <div
        onClick={toggleDrawer}
        className="p-[2px] flex flex-row justify-between w-full items-center text-lg outline outline-1 outline-black/10 rounded-full"
      >
        <div className="p-[2px] font-bold text-gray-600 lg:text-[16px] text-xs lg:h-[50px] lg:w-[50px] h-[45px] w-[45px] bg-gray-300 flex items-center rounded-full justify-center">
          {initials.toUpperCase()}
        </div>
      </div>
      <div
        className={`absolute bottom-0 right-0 transform translate-y-full w-[250px] z-[1000] ${drawerOpen ? "" : "pointer-events-none"
          }`}
      >
        <div
          onClick={toggleDrawer}
          className={`absolute left-0 top-0 h-screen duration-500 ${drawerOpen ? "bg-transparent" : "bg-transparent"
            }`}
        />
        <div
          className={`${drawerOpen
            ? " translate-y-[20px]"
            : " opacity-0 translate-y-[-100px]"
            } h-[90px] transition-all duration-[700ms] ease-in-out`}
        >
          <div
            className={` flex w-[250px] items-center justify-center p-2 shadow-2xl rounded-md bg-white outline outline-1 outline-black/10 right-0 transition-all duration-[700ms] ease-in-out`}
          >
            <div className="w-full h-full flex flex-col space-y-2">
              <div
                onClick={handleLogout}
                className="outline outline-[1px] outline-gray-300 cursor-pointer w-full h-[40px] bg-gray-100 rounded-sm flex flex-row items-center justify-center"
              >
                <div className="text-xs font-semibold">Log Out</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserIconDrawer;
