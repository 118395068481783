import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import moment from 'moment';

const processData = (bookings, viewMode, filterType, filterMonth) => {
    const dataByDate = {};

    // Get the start and end date of the selected period
    const startDate = filterType === 'month'
        ? moment(filterMonth).startOf('month')
        : moment(filterMonth).startOf('week');

    const endDate = filterType === 'month'
        ? moment(filterMonth).endOf('month')
        : moment(filterMonth).endOf('week');

    // Initialize all dates with 0 values for both types
    for (let m = startDate; m.isSameOrBefore(endDate); m.add(1, 'days')) {
        dataByDate[m.format('YYYY-MM-DD')] = { 'Meeting Room': 0, 'Space Room': 0 };
    }

    // Fill the dataByDate with actual booking data
    bookings.forEach(booking => {
        const date = booking.bookingDate;
        const type = booking.Worx.WorxTypeId === 1 ? 'Meeting Room' : 'Space Room';
        const amountOrQuantity = viewMode === 'amount' ? parseFloat(booking.totalAmount) : 1;

        if (dataByDate[date]) {
            dataByDate[date][type] += amountOrQuantity;
        }
    });

    return Object.keys(dataByDate).map(date => ({
        date,
        'Meeting Room': dataByDate[date]['Meeting Room'],
        'Space Room': dataByDate[date]['Space Room'],
    }));
};

const BarChart = ({ bookings, viewMode, filterType, filterMonth, width, height }) => {
    const svgRef = useRef();

    // Process the data for the chart
    const data = processData(bookings, viewMode, filterType, filterMonth);

    useEffect(() => {
        console.log("Bar Chart", data)

        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height);

        svg.selectAll('*').remove(); // Clear previous content

        const margin = { top: 20, right: 30, bottom: 40, left: 40 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Prepare scales
        const xScale = d3.scaleBand()
            .domain(data.map(d => d.date))
            .range([0, innerWidth])
            .padding(0.2);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => Math.max(d['Meeting Room'], d['Space Room']))])
            .nice()
            .range([innerHeight, 0]);

        const colorScale = d3.scaleOrdinal()
            .domain(['Meeting Room', 'Space Room'])
            .range(['steelblue', 'orange']);

        const g = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Create groups for each day
        const dayGroups = g.selectAll('.day-group')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', d => `translate(${xScale(d.date)}, 0)`);

        // Append bars for Meeting Room and Space Room
        dayGroups.selectAll('rect')
            .data(d => ['Meeting Room', 'Space Room'].map(key => ({ key, value: d[key], date: d.date })))
            .enter()
            .append('rect')
            .attr('x', (d, i) => i * (xScale.bandwidth() / 2))
            .attr('y', d => yScale(d.value))
            .attr('width', xScale.bandwidth() / 2)
            .attr('height', d => d.value > 0 ? innerHeight - yScale(d.value) : 0) // Skip rendering if value is 0
            .attr('fill', d => d.value > 0 ? colorScale(d.key) : 'none') // Only apply color if value > 0
            .on('mouseover', (event, d) => {
                if (d.value > 0) { // Only show tooltip if value > 0
                    const tooltip = d3.select('#tooltip')
                        .style('visibility', 'visible')
                        .text(`${d.key}: ${d.value}`);

                    d3.select(event.currentTarget).attr('fill', d3.rgb(colorScale(d.key)).darker(2));
                }
            })
            .on('mousemove', event => {
                d3.select('#tooltip')
                    .style('top', `${event.pageY - 10}px`)
                    .style('left', `${event.pageX + 10}px`);
            })
            .on('mouseout', (event, d) => {
                if (d.value > 0) {
                    d3.select('#tooltip').style('visibility', 'hidden');
                    d3.select(event.currentTarget).attr('fill', colorScale(d.key));
                }
            });

        // Draw X axis
        g.append('g')
            .attr('transform', `translate(0,${innerHeight})`)
            .call(d3.axisBottom(xScale).tickFormat(d => moment(d).format('DD-MM')));

        // Draw Y axis
        g.append('g')
            .call(d3.axisLeft(yScale));

        // Add labels
        svg.append('text')
            .attr('x', width / 2)
            .attr('y', height - 5)
            .attr('text-anchor', 'middle')
            .text('Date');

        svg.append('text')
            .attr('x', -height / 2)
            .attr('y', 15)
            .attr('transform', 'rotate(-90)')
            .attr('text-anchor', 'middle')
            .text(viewMode === 'amount' ? 'Total Amount' : 'Number of Bookings');

    }, [bookings, viewMode, filterType, filterMonth, width, height, data]);

    return (
        <>
            <svg ref={svgRef}></svg>
            <div id="tooltip" style={{
                position: 'absolute',
                backgroundColor: 'white',
                padding: '5px',
                border: '1px solid black',
                borderRadius: '5px',
                pointerEvents: 'none',
                visibility: 'hidden'
            }}></div>
        </>
    );
};

export default BarChart;
