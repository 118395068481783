import React, { useState, useEffect } from 'react';
import { uploadVendorNameImageAndGetURL } from "../Api/firebaseService"; // Create this function in firebaseService for hotel names
import { SpaceAPIService } from '../Api/spaceAPIService';

interface VendorNameUploadComponentProps {
    vendorId: number;
    existingNameUrl?: string; // Optional prop for an existing name URL
}

const VendorNameUploadComponent: React.FC<VendorNameUploadComponentProps> = ({
    vendorId,
    existingNameUrl
}) => {
    const [nameUrl, setNameUrl] = useState(existingNameUrl || '');

    const vendorAPIService = new SpaceAPIService();

    useEffect(() => {
        if (existingNameUrl) {
            setNameUrl(existingNameUrl);
        }
    }, [existingNameUrl]);

    const handleNameChange = async (file: File) => {
        let uploadedNameUrl = '';

        console.log(vendorId);

        try {
            // Upload the new name image and get URL
            uploadedNameUrl = await uploadVendorNameImageAndGetURL(file, vendorId);

            // Set the uploaded name URL as the new name image
            setNameUrl(uploadedNameUrl);

            const spaceData = {
                hotelNamePhoto: uploadedNameUrl
            };

            const response = await vendorAPIService.updateSpace(vendorId, spaceData);
            console.log("Hotel name uploaded", response);

        } catch (error) {
            console.error("Error uploading hotel name image: ", error);
        }
    };

    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            await handleNameChange(file);
        }
    };

    return (
        <div className="name-upload-wrapper" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div className="name-upload-container" style={{
                border: nameUrl ? '2px solid #4CAF50' : '2px dashed gray',
                padding: '15px',
                width: '75vw',
                height: '200px', // Slightly smaller height for a name
                backgroundImage: `url(${nameUrl})`,
                backgroundSize: 'contain', // Contain the name image within the box
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
            </div>

            <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                id="name-upload"
            />
            <label htmlFor="name-upload" style={{
                marginTop: '15px',
                cursor: 'pointer',
                padding: '10px 20px',
                backgroundColor: nameUrl ? '#FFA500' : '#007BFF',
                color: 'white',
                borderRadius: '5px',
                fontSize: '16px',
                textAlign: 'center',
            }}>
                {nameUrl ? 'Update Name' : 'Upload Name'}
            </label>
        </div>
    );
};

export default VendorNameUploadComponent;
