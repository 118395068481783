import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WorxAPIService from '../../Api/worxAPIService.ts';
import styles from './worxIframe.module.css';
import Header from '../components/header.tsx';
import Footer from '../components/footer.tsx';
import HoverEffectDiv from '../../effects/HoverEffectDiv.tsx';
import { FiUser } from 'react-icons/fi';
import MeetingRoomsSection from './MeetingRoomSection.tsx';
import WorkSpacesSection from './WorkSpacesSection.tsx';

const WorxIframe = () => {
  const { id, venueName } = useParams();

  const [hotelDetails, setHotelDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState("worxSpaces");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [numPersons, setNumPersons] = useState(2);
  const navigate = useNavigate();

  const goToWorxDetail = (worxId) => {
    navigate(`/space/detail?spaceId=${worxId}`, { state: { search: { day: selectedDate, numPeople: numPersons }, isFree: 1 } });
  };

  const incrementPersons = () => setNumPersons((prev) => prev + 1);
  const decrementPersons = () => setNumPersons((prev) => (prev > 1 ? prev - 1 : 1));

  const handleDateChange = (e) => setSelectedDate(e.target.value);

  useEffect(() => {
    const fetchHotelDetails = async () => {
      try {
        const details = await new WorxAPIService().getWorxByVenueURLName(venueName);

        console.log(details)

        if (details.body.msg && details.body.msg.includes("No spaces found")) {
          //navigate to root
          navigate("/")
        }

        setHotelDetails(details.body);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchHotelDetails();
  }, [venueName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!hotelDetails) return <div>No details available</div>;

  const meetingRooms = hotelDetails.worxes?.filter(worx => worx.worx_type === 1) || [];
  const workSpaces = hotelDetails.worxes?.filter(worx => worx.worx_type === 2) || [];

  return (
    <div className={styles.bgGray100}>
      <Header>
        <></>
      </Header>
      <div className={styles.container}>
        <div className={styles.worxContainer}>
          <div className={styles.contentWrapper}>
            {/* Hotel Image with Logo Overlay */}
            <div className={styles.hotelImageContainer}>
              {/* Conditionally render hotel logo if available */}
              {hotelDetails.hotel_logo && (
                <img
                  src={hotelDetails.hotel_logo}
                  alt={`${hotelDetails.hotel_name} logo`}
                  className={styles.hotelLogoOverlay}
                />
              )}

              {/* Hotel Banner Image */}
              <img
                src={hotelDetails.venue_photo}
                alt={hotelDetails.hotel_name}
                className={styles.hotelImage}
              />
            </div>

            <div className={styles.hotelNameContainer}>
              {hotelDetails.hotel_name_photo ? (
                <img
                  src={hotelDetails.hotel_name_photo}
                  alt={hotelDetails.hotel_name}
                  className={styles.hotelNamePhoto}
                />
              ) : (
                <h1 className={styles.hotelTitle}>{hotelDetails.hotel_name}</h1>
              )}
            </div>

            <h1 className={styles.hotelTagline}>{hotelDetails.hotel_tagline}</h1>
            <p className={styles.hotelAddress}>{hotelDetails.hotel_address}</p>
            <p className={styles.hotelCity}>{hotelDetails.hotel_city}, {hotelDetails.hotel_county}, {hotelDetails.hotel_eircode}</p>
            <p className={styles.hotelPhone}>Phone: {hotelDetails.hotel_phone}</p>
            <p className={styles.hotelEmail}>Email: {hotelDetails.hotel_email}</p>

            <div className={styles.toggleContainer}>
              <HoverEffectDiv
                text="Worx Spaces"
                onClick={() => setActiveSection("worxSpaces")}
                isSelected={activeSection === "worxSpaces"}
              />
              <HoverEffectDiv
                text="Meeting Rooms"
                onClick={() => setActiveSection("meetingRooms")}
                isSelected={activeSection === "meetingRooms"}
                disabled={true}
                tooltipMessage="Coming Soon"
              />
              <HoverEffectDiv
                text="Offers"
                onClick={() => setActiveSection("offers")}
                isSelected={activeSection === "offers"}
                disabled={true}
                tooltipMessage="Coming Soon"
              />
            </div>

            <div className={`${styles.inputContainer} ${activeSection === "worxSpaces" ? styles.wide : ''}`}>
              <div className={`${styles.datePickerContainer} ${activeSection === "worxSpaces" ? styles.wide : ''}`}>
                <input
                  className={`${styles.inputField} ${styles.datePicker} ${activeSection === "worxSpaces" ? styles.wide : ''}`}
                  type="date"
                  placeholder="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              {activeSection === "meetingRooms" && (
                <div className={styles.personsContainer}>
                  <div className={styles.personsSelector}>
                    <button onClick={decrementPersons} className={styles.decrementButton}>-</button>
                    <span className='flex items-center mx-2'> <FiUser className="mr-2" />{numPersons}</span>
                    <button onClick={incrementPersons} className={styles.incrementButton}>+</button>
                  </div>
                </div>
              )}
            </div>

            {activeSection === "meetingRooms" ? (
              <MeetingRoomsSection meetingRooms={meetingRooms} goToWorxDetail={goToWorxDetail} />
            ) : (
              <WorkSpacesSection workSpaces={workSpaces} goToWorxDetail={goToWorxDetail} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WorxIframe;
