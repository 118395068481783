import React from "react";
import { useState, useEffect } from "react";
import { SpaceDetail } from "../Models/spaceDetails.ts";
import MainContentImageDisplay from "./mainContentImageDisplay.tsx";
import EditableTextField from "../Components/editableTextField.tsx";
import { CreateDealModal } from "../Components/Worx/createModal.tsx";
import { useDebounce } from "../Hooks/usedDebounce.tsx";
import { userState } from "../State/userAtom.ts";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/header.tsx";
import { FiArrowLeft } from "react-icons/fi";
import { RiParentLine } from "react-icons/ri";
import { SpaceAPIService } from "../Api/spaceAPIService.ts";
import {
  deleteUserById,
  signUp,
  AuthResponse,
  login,
  isLoggedIn,
  logOut,
  createJoinCode,
} from "../Api/firebaseService.ts";
import { AiOutlineDelete, AiOutlineMenu } from "react-icons/ai";
import { BiCheck, BiLink, BiSave, BiUser } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { signOut } from "firebase/auth";
import { BiGlobe } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";
import useScrollNearBottom from "../Hooks/useScrollNearBottom.ts";
import useScrollNearTop from "../Hooks/useScrollNearTop.ts";
import getLatLngFromEircode from "../Hooks/getLatLngFromEircode.ts";
import { eircodesState } from "../State/eircodesAtom.ts";
import WorxAPIService from "../Api/worxAPIService.ts";
import ConfirmationModal from "./confirmationModal.tsx";
import { WorxDetail } from "../Models/worxDetails.ts";
import { Amenity } from "../Models/amenity.ts";
import { ReactTags } from "react-tags-ruki";
import { LuTags } from "react-icons/lu";
import { worxListState } from "../State/worxAtom.ts";
import { spaceListState } from "../State/spaceAtom.ts";
import { PhotoDetails } from "../Models/photoDetails.ts";
import WorxPhotoAPIService from "../Api/worxPhotoAPIService.ts";
import { Photo } from "../Models/photos.ts";
import { Booking } from "../Models/booking.tsx";
import BookingTable from "../Components/BookingTable.tsx";
import OpeningClosingTimePicker from "../Components/timePicker.tsx";
import DayPicker from "../Components/dayPicker.tsx";
import TimePriceTable from "../Components/TimePriceTable.jsx";
import ImageUploadComponent from "../Components/UploadImage.tsx";
import { SERVER_URL } from "../Utils/constants.js";
import ImageVendorUploadComponent from "../Components/imageVendorUploadComponent.tsx";
import { AmenitieAPIService } from "../Api/amenityAPIService.ts";
import '../css/worxContentManagement.css'

function useStateSafe<T>(initialValue: T | null) {
  const [state, setState] = useState(initialValue);
  const setSafeState = (newValue: T | null) => {
    if (newValue !== null) {
      setState(newValue);
    }
  };
  return [state, setSafeState] as const;
}

interface MeetingRoomsContentManagerProps {
  worx: WorxDetail;
  userIntials: string;
  parentSpace: SpaceDetail;
  availableAmenities: any;
}

interface Field {
  name: string;
  label: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MeetingRoomsContentManager({
  worx,
  userIntials,
  parentSpace,
  availableAmenities
}: MeetingRoomsContentManagerProps) {
  const terms = `The standard Lorem Ipsum passage, used since the 1500s
"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

1914 translation by H. Rackham
"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"

Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

1914 translation by H. Rackham
"On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains."`;

  const navigate = useNavigate();
  const parentId = parentSpace.id;
  const parentOpeningHour = parentSpace.openingHour;
  const parentClosingHour = parentSpace.closingHour;
  const worxId = worx.id;
  const [bookings, setBookings] = useState<Booking[]>([]);

  const convertListOfPhotosToRecord = (
    photos: Photo[]
  ): Record<number, string> => {
    const recordObj: Record<number, string> = {};
    for (let i = 0; i < photos.length; i++) {
      recordObj[i] = photos[i].photo_url;
    }
    return recordObj;
  };

  const currentUser = useRecoilValue(userState);
  const spaceAPIService = new SpaceAPIService();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const worxPhotoAPIService = new WorxPhotoAPIService();

  const [currentSelectedEditableField, setCurrentSelectedEditableField] =
    useState<string>("");

  const [worxData, setWorxData] = useState<WorxDetail>(worx);
  const photos = convertListOfPhotosToRecord(worxData?.WorxPhotos || []);

  const [currentHotelPictures, setCurrentHotelPictures] =
    useState<Record<number, string>>(photos);

  const [createNewDeal, setcreateNewDeal] = useState(false);

  const isUserInitialsTwoQuestionMarks = userIntials === "??";
  const [eircodes, setEircodes] = useRecoilState(eircodesState);
  const [worxListCache, setWorxListCache] = useRecoilState(worxListState);
  const [spaceListCache, setSpaceListCache] = useRecoilState(spaceListState);

  const isEditable: boolean = true;
  const worxAPIService = new WorxAPIService();

  const [disableScroll, setDisableScroll] = useState(false);

  const doesUserNameContainQuestionMark =
    currentUser.firebaseUser.firstName.includes("?") || currentUser.firebaseUser.lastName.includes("?");
  const [popupTopMargin, setPopupTopMargin] = useState("90px");
  const [popupBottomMargin, setPopupBottomMargin] = useState("5vh");
  const [popupHeight, setPopupHeight] = useState("90px");
  const ref = React.useRef<HTMLDivElement>(null);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [shake, setShake] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [isNew, setIsNew] = useState(true);
  const [amenities, setAmenities] = useState([] as Amenity[]);
  const [showSaveButton, setShowSaveButton] = useState(
    doesUserNameContainQuestionMark
  );
  const [user, setUser] = useRecoilState(userState);
  const [fromTime, setOpeningTime] = useState("09:00");
  const [toTime, setClosingTime] = useState("09:00");
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [availability, setAvailability] = useState({
    Monday: { open: null, close: null, isSelected: false },
    Tuesday: { open: null, close: null, isSelected: false },
    Wednesday: { open: null, close: null, isSelected: false },
    Thursday: { open: null, close: null, isSelected: false },
    Friday: { open: null, close: null, isSelected: false },
    Saturday: { open: null, close: null, isSelected: false },
    Sunday: { open: null, close: null, isSelected: false },
  });

  const [rows, setRows] = useState([]);

  const amenitieAPIService = new AmenitieAPIService();

  const [selectedAmenities, setSelectedAmenities] = useState(worxData.Amenities.map(amenity => amenity.id));

  // Function to update the rows state when a price row is added or modified
  const updateRows = (updatedRows) => {
    setRows(updatedRows);
  };

  const handleDaySelectionChange = (day, isSelected) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], isSelected },
    }));

    setShowSaveButton(true)
    console.log(availability)
  };

  const handleOpeningTimeChange = (day, time) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], open: time },
    }));
    setShowSaveButton(true)
  };

  const handleClosingTimeChange = (day, time) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], close: time },
    }));
    setShowSaveButton(true)
  };

  useEffect(() => {
    daysOfWeek.forEach(day => {
      const openKey = `opening_time_${day.toLowerCase()}`;
      const closeKey = `closing_time_${day.toLowerCase()}`;
      const openTime = parentSpace.VenueAvailability[openKey];
      const closeTime = parentSpace.VenueAvailability[closeKey];
      const isSelected = openTime !== null && closeTime !== null;

      // Update each day's selected status based on availability data
      handleDaySelectionChange(day, isSelected);

      // Update opening and closing times
      if (openTime !== null) {
        handleOpeningTimeChange(day, openTime);
      }
      if (closeTime !== null) {
        handleClosingTimeChange(day, closeTime);
      }
    });
  }, [worxData]); // Only rerun if availabilityData changes

  const validate = () => {
    if (
      isNew &&
      (firstName === "" ||
        lastName === "" ||
        email === "" ||
        password !== repassword)
    ) {
      setShake(true);
      return false;
    } else if (!isNew && (email === "" || password === "")) {
      setShake(true);
      return false;
    }
    return true;
  };

  const navigateToSpace = (user: any) => {
    const numberOfSpaces = Object.keys(user.spaces).length;

    if (numberOfSpaces === 1) {
      setTimeout(() => {
        const spaceId = Object.keys(user.spaces)[0];
        navigate(`/space?id=${spaceId}`);
        window.location.reload();
      }, 50);

      return;
    }

    if (numberOfSpaces === 0) {
      navigate(`/spaces?total=${0}`);
      return;
    }

    navigate(`/spaces?total=${numberOfSpaces}`);
  };

  const handleSelectAmenity = (id) => {
    setSelectedAmenities(prevState =>
      prevState.includes(id) ? prevState.filter(amenityId => amenityId !== id) : [...prevState, id]
    );
  };

  const saveNewAmenities = async () => {
    selectedAmenities.forEach(amenitie => {
      const body = {
        "WorxId": worxId,
        "AmenitieId": amenitie
      }

      const response = amenitieAPIService.createAmenitieWorx(body);
    })
  }

  const deleteAllAmenities = async () => {
    const response = await amenitieAPIService.deleteAmenitieWorx(worxId);
  }

  const fieldStyle = "border border-gray-400 p-2 mb-4 rounded-lg";

  const fields: { [key: string]: Field } = {
    "first name": {
      name: "first name",
      label: "First Name",
      type: "text",
      value: firstName,
      onChange: (e) => setFirstName(e.target.value),
    },
    "last name": {
      name: "last name",
      label: "Last Name",
      type: "text",
      value: lastName,
      onChange: (e) => setLastName(e.target.value),
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      value: repassword,
      onChange: (e) => setRePassword(e.target.value),
    },
  };

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const activeFields = isNew
    ? ["first name", "last name", "email", "password", "confirmPassword"]
    : ["email", "password"];

  const openTermsModal = () => {
    setShowActivationModal(false);
    setShowTermsModal(true);
  };

  const returnToActivationModal = () => {
    setShowTermsModal(false);
    setShowActivationModal(true);
  };

  const handleReturnToTermsModal = () => {
    setShowRegistrationModal(false);
    openTermsModal();
  };

  const openRegistrationModal = () => {
    setShowTermsModal(false);
    setShowActivationModal(false);
    setShowRegistrationModal(true);
  };

  useEffect(() => {
    if (ref.current) {
      setPopupHeight(`${ref.current.clientHeight}px`);
    }

    const fetchBookings = async () => {
      try {
        // Replace the following URL with your API endpoint to fetch bookings
        const response = await worxAPIService.getWorxBookings(worxId)

        const sortedBookings = response.body.sort((a: any, b: any) => {
          const dateA = new Date(a.bookingDate);
          const dateB = new Date(b.bookingDate);

          return dateB.getTime() - dateA.getTime();
        });
        setBookings(sortedBookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    }

    if (worxId && parentId) {
      fetchBookings();
    }
  }, []);

  function convertDateToTimeString(date: Date): string {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  const onOpeningTimeChange = (openingTime: Date) => {

    setOpeningTime(convertDateToTimeString(openingTime))
  }

  const onClosingTimeChange = (closingTime: Date) => {

    setClosingTime(convertDateToTimeString(closingTime))
  }

  const handleDayChange = (date: Date | null) => {
    setSelectedDay(date);
  };


  const handleCreateBooking = async () => {
    const data = {
      spaceId: 54,
      worxId: 174,
      from_time: fromTime,
      to_time: toTime,
      bookingDate: selectedDay
    }

    const resp = await worxAPIService.createBooking(data);

    const originalDate = new Date(resp.body.bookingDate);

    // Convert to YYYY-MM-DD format
    const formattedDate = originalDate.toISOString().split('T')[0];

    resp.body.bookingDate = formattedDate
    setBookings((prevBookings) => [resp.body, ...prevBookings]);
  }

  useScrollNearBottom((nearBottom: boolean) => {
    if (nearBottom) {
      setPopupBottomMargin("22vh");
    } else {
      setPopupBottomMargin("5vh");
    }
  }, 200);

  useScrollNearTop((nearTop: boolean) => {
    if (nearTop) {
      setPopupTopMargin("100px");
    } else {
      setPopupTopMargin("15px");
    }
  }, 70);

  useEffect(() => {
    if (worx !== null && worx !== undefined && Object.keys(worx).length > 0) {
      setWorxData(worx);
      console.log(worx)

      setRows(worx.SpacePrices.map(item => ({
        id: item.id, // Consider if you need a unique ID here or you will use hour as in the TimePriceTable
        hour: item.hour,
        price: item.price
      })).sort((a, b) => a.hour - b.hour));
    }
  }, [worx]);

  const handleDealCreation = () => {
    setcreateNewDeal(!createNewDeal);
  };

  const placeholder =
    "https://cdn.icon-icons.com/icons2/510/PNG/512/image_icon-icons.com_50366.png";

  const placeholderDescription =
    "lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua";

  function updateProperty<T extends Record<string, any>>(
    obj: T,
    propName: string,
    newValue: any
  ): T {
    if (Object.prototype.hasOwnProperty.call(obj, propName)) {
      return { ...obj, [propName]: newValue };
    }
    throw new Error(`Property ${propName} does not exist on object`);
  }

  const handleEditableTextSelection = (field: string) => {
    setCurrentSelectedEditableField(field);
    handleImageEditorVisability(field !== "");
  };

  const setSpace = (prop: string, value: any) => {
    let newWorx: WorxDetail | null = null;
    const hasProp = Object.prototype.hasOwnProperty.call(worxData, prop);
    if (hasProp) {
      newWorx = { ...worxData!, [prop]: value };
      setWorxData(newWorx);
    } else {
      // Handle the error case
    }

    return newWorx;
  };

  const updateWorxDetailsWithFields = async (fieldsToUpdate: {
    [key: string]: string;
  }) => {
    let locationData: any = null;
    const eircodeExists = "eircode" in fieldsToUpdate;
    let worxDetail = { ...worxData };

    if (eircodeExists) {
      const eircode = fieldsToUpdate["eircode"];
      const locationExists = eircode in eircodes;

      if (locationExists) {
        locationData = eircodes[eircode];
      } else {
        locationData = await getLatLngFromEircode(eircode);
        const updatedEircodes = { ...eircodes, [eircode]: locationData };
        setEircodes(updatedEircodes);
      }
    }

    // Update worxDetail with fields from fieldsToUpdate
    Object.keys(fieldsToUpdate).forEach((field) => {
      worxDetail = { ...worxDetail, [field]: fieldsToUpdate[field] };
    });

    // Update worxDetail with location data if available
    if (locationData) {
      Object.keys(locationData).forEach((key) => {
        worxDetail = { ...worxDetail, [key]: locationData[key] };
      });
    }

    setWorxData(worxDetail);
    return worxDetail;
  };

  const handleFieldSave = async (field: string, value: any) => {

    console.log(field)
    console.log(value)
    const isObject = !value.text;
    let newWorx: WorxDetail | null = null;

    const val = !isObject ? value.text : value;

    if (isObject && field !== "price") {
      newWorx = await updateWorxDetailsWithFields(value);
    } else {
      newWorx = setSpace(field, val);
    }

    if (newWorx === null || value === undefined) return;

    //checks if account is activated
    if (doesUserNameContainQuestionMark) return;

    let newWorxList = worxListCache.filter(
      (cachedWorx: WorxDetail) => cachedWorx.id !== newWorx!.id
    );

    newWorxList.push(newWorx!);

    setWorxListCache(newWorxList);

    let newSpaceList = spaceListCache.filter(
      (cachedSpace: SpaceDetail) => cachedSpace.id !== parentId
    );

    newSpaceList.push(parentSpace);

    setSpaceListCache(newSpaceList);

    setWorxData(newWorx!);
  };

  const debouncedHandleFieldSave = useDebounce(handleFieldSave, 100);
  const dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(",") as any,
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getTimeInMillis = () => {
    return new Date().getTime();
  };

  const handleDeleteSpace = async () => {
    if (doesUserNameContainQuestionMark) return;
    if (!worxData) return;
    const resp = await worxAPIService.deleteWorx(worxData.id);
    setTimeout(() => {
      navigate(getReturnLink());
      window.location.reload();
    }, 50);
    closeDeleteModal();
  };

  const createAvailabilityPayload = (availability) => {
    const payload = {
      opening_time_monday: availability.Monday.isSelected ? availability.Monday.open : null,
      closing_time_monday: availability.Monday.isSelected ? availability.Monday.close : null,
      opening_time_tuesday: availability.Tuesday.isSelected ? availability.Tuesday.open : null,
      closing_time_tuesday: availability.Tuesday.isSelected ? availability.Tuesday.close : null,
      opening_time_wednesday: availability.Wednesday.isSelected ? availability.Wednesday.open : null,
      closing_time_wednesday: availability.Wednesday.isSelected ? availability.Wednesday.close : null,
      opening_time_thursday: availability.Thursday.isSelected ? availability.Thursday.open : null,
      closing_time_thursday: availability.Thursday.isSelected ? availability.Thursday.close : null,
      opening_time_friday: availability.Friday.isSelected ? availability.Friday.open : null,
      closing_time_friday: availability.Friday.isSelected ? availability.Friday.close : null,
      opening_time_saturday: availability.Saturday.isSelected ? availability.Saturday.open : null,
      closing_time_saturday: availability.Saturday.isSelected ? availability.Saturday.close : null,
      opening_time_sunday: availability.Sunday.isSelected ? availability.Sunday.open : null,
      closing_time_sunday: availability.Sunday.isSelected ? availability.Sunday.close : null,
    };

    return JSON.stringify(payload);
  };

  const deleteAllPriceByWorxID = async () => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30';


    const responseAvailability = await fetch(`${SERVER_URL}prices/delete-worx/${worxData.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Add authorization header
      },
    });
  }

  function createPricePayload(hour, price) {
    // Convert the price string to a decimal format, handling empty strings
    const formattedPrice = price === "" ? null : parseFloat(price).toFixed(2);

    // Return the payload object
    return JSON.stringify({
      worxId: worxData.id,  // Set the worxId based on function input
      hour: hour,      // Set the hour based on function input
      price: formattedPrice  // Use the formatted price
    });
  }

  const saveNewPrices = async () => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30';

    rows.forEach(item => {
      fetch(`${SERVER_URL}prices/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Replace 'your_token_here' with your actual token
        },
        body: createPricePayload(item.hour, item.price)
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  }

  const handleSaveAllChanges = async () => {
    if (userIntials === "??") {
      setShowActivationModal(true);
      return;
    }

    if (!worxData) return;

    const resp = await worxAPIService.updateWorx(worxData.id, worxData);
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30';

    const payload = createAvailabilityPayload(availability)

    console.log(payload)
    const responseAvailability = await fetch(`${SERVER_URL}worx-availability/update/${worxData.SpaceAvailability.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Add authorization header
      },
      body: payload
    });

    await deleteAllPriceByWorxID();
    await saveNewPrices();

    await deleteAllAmenities();
    await saveNewAmenities();

    await savePhotos();
  };

  const savePhotos = async () => {
    const images = photoRecordToListOfPhotos(currentHotelPictures);

    if (images.length == 0) return;

    handleFieldSave("main_photo", images[0].photo_url);

    handleFieldSave("WorxPhotos", images);

    let resp = await worxPhotoAPIService.createBulkWorxPhoto(
      images,
      worxData!.id
    );
  };

  const listToRecord = (arr: string[]): Record<number, string> => {
    const recordObj: Record<number, string> = {};
    for (let i = 0; i < arr.length; i++) {
      recordObj[i] = arr[i];
    }
    return recordObj;
  };

  const createPhotoDetail = (imgSrc: string) => {
    let photoDetails: PhotoDetails = {
      photo_url: "",
      isMain: false,
      createdAt: "",
      updatedAt: "",
      Worx_Photo: {
        WorxId: worxData!.id,
        WorxPhotoId: 0,
        createdAt: "",
        updatedAt: "",
      },
    };

    return photoDetails;
  };

  const createPhotoFromUrl = (url: string): Photo => {
    return {
      photo_url: url,
      isMain: 0,
    };
  };

  const photoRecordToListOfPhotos = (
    photos: Record<number, string>
  ): Photo[] => {
    const photoList: Photo[] = [];
    Object.values(photos).forEach((photo) => {
      photoList.push(createPhotoFromUrl(photo));
    });
    return photoList;
  };

  const handleGallaryOnSave = async (images: string[]) => {
    if (!worx) return;
    const imagesRecord = listToRecord(images);
    setCurrentHotelPictures(imagesRecord);

    if (doesUserNameContainQuestionMark) {
      return;
    }
  };

  const getReturnLink = () => {
    return `/space?id=${parentId}`;
  };

  const deleteAccountAndClearAllData = async () => {
    const id = currentUser.id;
    deleteUserById(id);
    localStorage.clear();
    logOut();
  };

  const handleAccountActivation = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!worxData) return;

    if (!validate()) return;

    if (worxData) {
      await worxAPIService.updateWorx(worxData.id, worxData);
      await savePhotos();
    }

    deleteAccountAndClearAllData();

    let spaces = { [`${worxData.id}`]: 5 };

    const authResponse = await signUp(
      email,
      password,
      firstName,
      lastName,
      spaces
    );

    if (authResponse.user) {
      setUser(authResponse.user);
      navigateToSpace(authResponse.user);
    } else if (authResponse.error) {
      window.location.reload();
    }
  };

  const handleAccountDeactivation = async () => {
    setShowActivationModal(false);
    setShowSaveButton(true);
  };

  const handleTermsAndConditions = async () => {
    openTermsModal();
  };

  const handleTagUpdate = (tags: string[] | string) => {
    const lastTag = amenities[amenities.length - 1].name;
    const secondLastTag = tags[tags.length - 2];

    const newTag = secondLastTag.substring(lastTag.length);

    const newTag_ = (tags as string[]).slice(0, tags.length - 1);

    newTag_.push(lastTag);

    newTag_.push(newTag);

    const newAmenities = [
      ...amenities,
      {
        id: amenities.length + 1,
        name: newTag,
        description: "empty",
        icon: placeholder,
        createdAt: "2023-04-11T19:56:04.000Z",
        updatedAt: "2023-04-11T19:56:04.000Z",
      } as Amenity,
    ];

    //reverse the list
    newAmenities.reverse();

    setAmenities(newAmenities);

    //todo - get the tag saving working, internal error in the react-tags-ruki library
    //handleFieldSave("Amenities", newAmenities);
  };

  const details = worxData!.description
    ? worxData.description
    : placeholderDescription;

  const calculateTopMarginBasedOnPopUpHeight = () => {
    if (doesUserNameContainQuestionMark) {
      return showActivationModal ? "20vh" : "20px";
    } else {
      return "15px";
    }
  };

  const handleImageEditorVisability = (isVisable: boolean): void => {
    setShowActivationModal(false);
    setShowTermsModal(false);
    setShowRegistrationModal(false);
    setShowSaveButton(!isVisable);
  };

  useEffect(() => {
    if (worxData) {
      setAmenities(worxData["Amenities"]);
    }
  }, [worxData]);


  return (
    <AnimatePresence>
      <div className="w-full flex flex-col items-center relative overflow-hidden px-4 content-center">
        {createNewDeal && (
          <div className="fixed w-screen h-screen z-5">
            <div className="relative h-full w-full flex flex-col justify-center items-center">
              <div
                onClick={handleDealCreation}
                className="cursor-pointer bg-black/60 w-full h-full"
              ></div>
              <CreateDealModal
                onClose={() => { }}
                handleDealCreation={handleDealCreation}
                handleSpaceChange={() => { }}
              />
            </div>
          </div>
        )}
        <Header userInitials={userIntials} />
        {doesUserNameContainQuestionMark &&
          !showTermsModal &&
          showActivationModal && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: -120 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
              className="w-full h-full fixed z-[1000] mt-[14.5vh] pointer-events-none"
            >
              <div
                ref={ref}
                key={"activation_popup"}
                className="fixed z-[1000] flex flex-col items-center justify-center w-full space-y-2"
                style={{
                  transition: "margin-top 0.2s ease-out",
                  marginTop: popupTopMargin,
                }}
              >
                <div className="w-full max-w-3xl flex items-center justify-center">
                  <div
                    className={`${createNewDeal ? "pointer-events-none" : ""
                      } h-full w-full bg-white/70 flex flex-col p-6 outline-black/40 backdrop-blur-[15px] outline outline-[0.1px] shadow-2xl max-w-[1280px] items-start justify-center rounded-xl pointer-events-auto`}
                  >
                    <div
                      className={`w-full h-full flex justify-center ${false
                        ? "flex-col items-start text-3xl"
                        : "flex-row text-xl items-center"
                        }`}
                    >
                      <div className="absolute w-full">
                        <div className="xl:h-[35px] h-[32px] w-[100px] mr-6 mb-4 ml-auto">
                          <img
                            className="w-full h-full object-contain"
                            src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
                            alt="WORXINN"
                          />
                        </div>
                      </div>
                      <div className="text-black opacity-70 text-3xl mb-[10px] w-full text-start font-semibold">
                        {`
                  Activate account to enable saving.   `}
                      </div>
                    </div>
                    <div className="w-full h-full">
                      <div className="text-black opacity-70 w-full text-start font-semibold mb-6">
                        {`
                 By activiting the account you agree to the terms & conditions.

                `}
                      </div>
                      <div
                        className={`${createNewDeal ? "pointer-events-none" : ""
                          } h-full w-full flex flex-row items-center rounded-xl`}
                      >
                        <div className="flex flex-row items-start space-x-2">
                          <div
                            onClick={handleTermsAndConditions}
                            className=" rounded-lg bg-black/50 backdrop-blur-[15px] shadow-2xl hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                          >
                            <BiGlobe className="mr-2 text-white" />
                            <div className="text-white">
                              {"Terms & Condtions"}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 ml-auto ">
                          <div
                            onClick={handleTermsAndConditions}
                            className="w-[50%] rounded-md shadow bg-green-200 hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:outline-black/40 hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                          >
                            <BiCheck className="mr-2 text-black" />
                            <div className="text-black">{"Activate"}</div>
                          </div>
                          <div
                            onClick={handleAccountDeactivation}
                            className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full hover:shadow-md hover 
                            hover:outline-offset-1 cursor-pointer rounded-md bg-red-200 py-1 px-3 font-semibold flex flex-row items-center justify-center 
                            outline-black/40 outline outline-[0.1px]"
                          >
                            <RxCross2 className="mr-2 text-black" />
                            <div className="text-black">{"Deactivate"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-3xl flex items-center justify-center translate-y-[-2px]"></div>
              </div>
            </motion.div>
          )}

        {showTermsModal && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: -120 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 left-0 w-screen h-[200vh] flex items-start justify-center z-[1100] bg-black/30 "
          >
            <div
              style={{
                transition: "margin-top 0.2s ease-out",
                transform: "translateY(140px)",
                marginTop: popupTopMargin,
              }}
              className="w-full max-w-md mx-auto tline-black/70 z-[110] bg-white/80 backdrop-blur-[105px] p-4 rounded-lg shadow-lg pointer-events-auto"
            >
              <h2 className="text-lg font-bold mb-2">Terms & Conditions</h2>
              <div className="overflow-auto h-64 mb-4 p-2 border border-gray-200 rounded">
                {terms}
              </div>
              <div className="flex justify-between">
                <div
                  onClick={returnToActivationModal}
                  className=" rounded-lg bg-black/50 backdrop-blur-[15px] shadow-2xl hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                >
                  <RxCross2 className="mr-2 text-white" />
                  <div className="text-white">{"Return"}</div>
                </div>
                <div
                  onClick={openRegistrationModal}
                  className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer rounded-md bg-green-200 py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                >
                  <BiCheck className="mr-2 text-black" />
                  <div className="text-black">{"Activate & Agree"}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDeleteSpace}
          message="Are you sure you want to delete this space?"
        />

        {showRegistrationModal && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: -120 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 left-0 w-screen h-[200vh] flex items-start justify-center z-[1100] bg-black/30"
          >
            <div
              style={{
                transition: "margin-top 0.2s ease-out",
                transform: "translateY(140px)",
                marginTop: popupTopMargin,
              }}
              className={`w-full max-w-md z-[1200] mx-auto outline-black/70 bg-white/80 backdrop-blur-[105px] p-4 rounded-lg shadow-lg pointer-events-auto`}
            >
              <h2 className="text-xl font-bold mb-4">Complete Profile</h2>
              <form
                className="flex flex-col duration-300 transition-all"
                onSubmit={handleAccountActivation}
              >
                {activeFields.map((key) => (
                  <div className="flex flex-col" key={fields[key].label}>
                    <label className="text-sm text-gray-500 mb-2">
                      {fields[key].label}
                    </label>
                    <input
                      className={fieldStyle}
                      type={fields[key].type}
                      name={fields[key].name}
                      value={fields[key].value}
                      onChange={fields[key].onChange}
                    />
                  </div>
                ))}
                <div className="flex justify-between">
                  <div
                    onClick={handleReturnToTermsModal}
                    className=" rounded-lg bg-black/50 backdrop-blur-[15px] shadow-2xl hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                  >
                    <RxCross2 className="mr-2 text-white" />
                    <div className="text-white">{"Return"}</div>
                  </div>
                  <button
                    type="submit"
                    className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full 
                    hover:shadow-md hover hover:outline-offset-1 cursor-pointer rounded-md bg-green-200 py-1 px-3 font-semibold flex flex-row items-center 
                    justify-center outline-black/40 outline outline-[0.1px]"
                  >
                    <BiUser className="mr-2 text-black" />
                    <div className="text-black">{"Create Account"}</div>
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        )}

        <div className="fixed w-screen h-screen items-center justify-center z-[999] flex pointer-events-none">
          <div className="w-[89vw] lg:w-[70vw] max-w-[1280px] h-full flex pointer-events-none">
            {showSaveButton && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                className="w-full h-full flex "
              >
                <div
                  style={{
                    transition: "margin-bottom 1s ease-in-out",
                    marginBottom: popupBottomMargin,
                  }}
                  onClick={handleSaveAllChanges}
                  className="w-[130px] h-[38px] pointer-events-auto text-lg mt-auto ml-auto transition-all duration-300 shadow hover:scale-[1.03] 
                  hover:outline-dashed hover:outline-[3px] hover:outline-orange-400/40 hover:shadow-md hover hover:outline-offset-2 cursor-pointer rounded-lg bg-orange-500 
                  py-1 px-3 font-semibold flex flex-row items-center justify-center outline-orange-400/40 outline outline-[0.1px] "
                >
                  <BiSave className="mr-2 text-white text-xl" />
                  <div className="text-white">{"Save"}</div>
                </div>
              </motion.div>
            )}
          </div>
        </div>

        <div
          style={{
            transition: "margin-top 0.5s ease-in-out",
            marginTop: calculateTopMarginBasedOnPopUpHeight(),
          }}
          className={`min-h-[85vh]`}
        >
          <div
            className={`${createNewDeal ? "pointer-events-none" : ""
              } h-full w-[89vw] lg:w-[70vw] max-w-[1280px] flex flex-col items-center`}
          >
            <div className="w-full flex flex-row h-[35px]">
              <div className="rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center  duration-300 hover:outline-dashed hover:outline-2 hover:outline-black/20 hover hover:outline-offset-4 hover:rounded-lg">
                <a
                  href={getReturnLink()}
                  className="  flex flex-row no-underline text-black group hover:text-orange-500 hover:cursor-pointer items-center"
                >
                  <FiArrowLeft className="h-full mr-2 text-xl font-bold group-hover:text-orange-500" />
                  <div className="text-base font-semibold group-hover:text-orange-500">
                    {"Return to Parent"}
                  </div>
                </a>
              </div>

              {currentUser.role >= 10 && (
                <div
                  onClick={openDeleteModal}
                  className="ml-2 rounded-lg bg-red-500 py-1 px-4 cursor-pointer text-white flex flex-row items-center 
                  justify-center duration-100 hover:outline-dashed hover:outline-3 hover:outline-red-500/50 hover hover:outline-offset-4 
                  hover:rounded-lg"
                >
                  <AiOutlineDelete className="mr-2 " />
                  <div className="font-bold">{"Delete Space"}</div>
                </div>
              )}
              <div className="ml-auto flex flex-row space-x-3 h-[40px] justify-end">
                <div className=" rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center">
                  <RiParentLine className="mr-2" /> <div>{"Child"}</div>
                </div>
              </div>
            </div>

            <div id="title-section" className="flex flex-col w-full mt-4 mb-4">
              <div className="w-full mt-2">
                <EditableTextField
                  label="Space Name"
                  currentSelectedEditableField={currentSelectedEditableField}
                  initialValue={
                    worxData.name ? worxData.name : "Space #" + worxData.id
                  }
                  onSave={(value) => {
                    debouncedHandleFieldSave("name", value);
                  }}
                  tailwindTextStyle="text-xl font-bold"
                  onSelect={(currentSelectedLabel) => {
                    handleEditableTextSelection(currentSelectedLabel);
                  }}
                  onDeselect={() => {
                    handleEditableTextSelection("");
                  }}
                  isEditable={isEditable}
                />
              </div>
            </div>

            <ImageVendorUploadComponent isVendorUpload={false} isWorxUpload={true} id={worxData.id} existingImageUrl={worxData.WorxPhotos.length > 0 ? worxData.WorxPhotos[0].photo_url : ''} />

            {amenities && amenities.length > 0 && (
              <div
                id="amenities-section"
                className={`${createNewDeal ? "pointer-events-none" : ""
                  }  mt-10 h-full w-[89vw] lg:w-[70vw] max-w-[1280px] flex flex-col items-center`}
              >
                <div className="text-md font-semibold focus:outline-0 w-full mb-2">
                  Amenities
                </div>
                <div className="flex flex-row flex-wrap space-x-2 w-full">
                  <ReactTags
                    defaultTags={amenities.map(
                      (amenity: Amenity) => amenity.name
                    )}
                    inputPosition="end"
                    delimeter=","
                    onTagUpdated={handleTagUpdate}
                    title="Amenities"
                    textFieldProps={{
                      variant: "default",
                      leading: (
                        <div className="w-[27px] h-[27px] rounded-full mr-[10px] flex items-center">
                          <LuTags className="w-full h-full text-gray-400" />
                        </div>
                      ),

                      enableOutline: false,
                      style: {
                        color: "black",
                        padding: "6px 12px 6px 12px",
                        marginRight: "8px",
                        borderRadius: "10px",
                        minWidth: "100px",
                        outlineColor: "rgba(200, 200, 200, 1)",
                        outlineStyle: "dashed",
                        outlineWidth: "3px",
                        marginBottom: "10px",
                        caretColor: "rgba(200, 200, 200, 1)",
                      },
                      placeholder: "Add Amenity",
                    }}
                    chipsProps={amenities.map((amenity: Amenity) => {
                      let img = amenity.icon ?? placeholder;
                      let style_amenity_icon = "";

                      if (!img.includes("http")) {
                        img = placeholder;
                        style_amenity_icon = "filter grayscale opacity-[0.3]";
                      }

                      return {
                        closeIcon: (
                          <div className="w-[20px] h-[20px] rounded-full mr-[3px] overflow-hidden">
                            <RxCross2 className="w-full h-full" />
                          </div>
                        ),
                        leading: (
                          <div className="w-[30px] h-[30px] rounded-full mr-[3px] cursor-pointer hover:scale-[1.3]">
                            <img
                              className={`object-cover w-full h-full p-[2px] cursor-pointer ${style_amenity_icon}`}
                              src={img}
                            />
                          </div>
                        ),
                        readOnly: true,
                        variant: "filled",

                        style: {
                          backgroundColor: "rgba(0,0,0,0.1)",
                          padding: "6px 20px 6px 20px",
                          marginRight: "8px",
                          borderRadius: "10px",
                          color: "black",
                          marginBottom: "10px",

                        },
                      };
                    })}
                  />
                </div>
              </div>
            )}
            <div
              id="amenities-section"
              className={`${createNewDeal ? "pointer-events-none" : ""
                }  mt-10 h-full w-[89vw] lg:w-[70vw] max-w-[1280px] flex flex-col items-center`}
            >
              <div className="text-md font-semibold focus:outline-0 w-full mb-2">
                Amenities
              </div>
              {
                availableAmenities && (
                  <div className="amenities-container">
                    {availableAmenities.map((amenity) => (
                      <div
                        key={amenity.id}
                        className={`amenity ${selectedAmenities.includes(amenity.id) ? 'selected' : ''}`}
                        onClick={() => handleSelectAmenity(amenity.id)}
                      >
                        <img src={`/amenities/${amenity.icon}`} alt={amenity.name} className="amenity-icon" />
                        <div className="amenity-info">
                          <h3 className="amenity-name">{amenity.name}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
            <div
              id="description-section"
              className={`flex flex-col w-full mt-2 ${currentSelectedEditableField == "Description"
                ? "z-[3]"
                : "z-[1]"
                }`}
            >
              <div className="text-md font-semibold focus:outline-0 w-full mb-2">
                Description
              </div>
              <EditableTextField
                label="Description"
                currentSelectedEditableField={currentSelectedEditableField}
                initialValue={details}
                onSave={(value) =>
                  debouncedHandleFieldSave("description", value)
                }
                tailwindTextStyle={`text-sm ${currentSelectedEditableField == "Description"
                  ? "text-black/100"
                  : "text-black/40"
                  }`}
                onSelect={(currentSelectedLabel) => {
                  handleEditableTextSelection(currentSelectedLabel);
                }}
                onDeselect={() => {
                  handleEditableTextSelection("");
                }}
                isEditable={isEditable}
              />
              <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
                Capacity
              </div>
              <EditableTextField
                label="Capacity"
                currentSelectedEditableField={currentSelectedEditableField}
                initialValue={worxData.capacity}
                onSave={(value) => debouncedHandleFieldSave("capacity", value)}
                tailwindTextStyle={`text-sm ${currentSelectedEditableField == "Capacity"
                  ? "text-black/100"
                  : "text-black/40"
                  }`}
                onSelect={(currentSelectedLabel) => {
                  handleEditableTextSelection(currentSelectedLabel);
                }}
                onDeselect={() => {
                  handleEditableTextSelection("");
                }}
                isEditable={isEditable}
                numberOnly={true}
              />
            </div>
          </div>
          <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
            Opening and Closing Times
          </div>
          {worxData && <OpeningClosingTimePicker
            availability={parentSpace.VenueAvailability}
            onDaySelectionChange={handleDaySelectionChange}
            onOpeningTimeChange={handleOpeningTimeChange}
            onClosingTimeChange={handleClosingTimeChange}
          />}

          <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
            Price
          </div>
          <TimePriceTable availability={availability} initialData={rows} setRows={updateRows} />
          <div>
            <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
              Bookings
            </div>

            <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
              Create manual booking
            </div>
            <div>

              <DayPicker onDayChange={handleDayChange} />

              <OpeningClosingTimePicker
                initialOpeningTime="09:00:00"
                initialClosingTime="09:00:00"
                minTime={parentOpeningHour}
                maxTime={parentClosingHour}
                labelFirstTimePicker="From Time"
                labelSecondTimePicker="To Time"
                onOpeningTimeChange={onOpeningTimeChange}
                onClosingTimeChange={onClosingTimeChange} />


              <div
                style={{
                  transition: "margin-bottom 1s ease-in-out",
                  marginBottom: popupBottomMargin,
                }}
                onClick={handleCreateBooking}
                className="w-[175px] h-[38px] pointer-events-auto text-lg mt-auto transition-all duration-300 shadow hover:scale-[1.03] 
                  hover:outline-dashed hover:outline-[3px] hover:outline-orange-400/40 hover:shadow-md hover hover:outline-offset-2 cursor-pointer rounded-lg bg-orange-500 
                  py-1 px-3 font-semibold items-center justify-center outline-orange-400/40 outline outline-[0.1px] "
              >
                <div className="text-white">{"Save booking"}</div>
              </div>
            </div>

            {bookings.length > 0 ? <BookingTable bookings={bookings} /> : <></>}

          </div>
        </div>
        <div className="w-full h-[20vh] bg-orange-600 flex flex-col items-center object-cover mt-[120px]">
          <div className=" h-full w-[70vw] max-w-[1280px] flex flex-col items-center justify-center"></div>
        </div>
      </div>
    </AnimatePresence>
  );
}
