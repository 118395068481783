import {
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { EmailAPIService } from '../../Api/emailAPIService';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import { SERVER_URL } from '../../Utils/constants';
import { useNavigate } from 'react-router-dom';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const emailAPIService = new EmailAPIService();
  const [user, setUser] = useRecoilState(userState);
  const [userEmail, setUserEmail] = useState(user && Object.keys(user).length !== 0 ? user.bbdUser.user.email : '');
  const [userName, setUserName] = useState(user?.bbdUser?.user?.firstName || '');
  const isGuest = Object.keys(user).length === 0;
  const navigate = useNavigate();

  console.log(user)
  console.log(user?.bbdUser?.user?.email)
  console.log(userEmail)
  const createBooking = async () => {
    try {
      const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30'; // Replace with actual auth token

      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const formattedToday = `${year}-${month}-${day}`;

      const userId = user?.bbdUser?.user?.id || 24; // Use a default user ID if guest

      console.log(props.booking.seatNumber)

      const response = await fetch(`${SERVER_URL}booking/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Add authorization header
        },
        body: JSON.stringify({
          spaceId: props.space.id,
          worxId: props.worx.id,
          from_time: props.booking.from_time,
          to_time: props.booking.to_time,
          createdAt: formattedToday,
          updatedAt: formattedToday,
          bookingDate: props.booking.day,
          userId: userId,
          hours: props.booking.numHours,
          totalAmount: props.booking.price,
          seatNumber: props.booking.seatNumber,
          guestName: isGuest ? userName : undefined, // If guest, add name
          guestEmail: isGuest ? userEmail : undefined, // If guest, add email
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create booking');
      }

      const data = await response.json();
      console.log('Booking created:', data);

      const body = {
        booking: {
          id: data.body.id,
          day: props.booking.day,
          from_time: props.booking.from_time,
          to_time: props.booking.to_time,
          totalAmount: props.booking.price,
          hours: props.booking.numHours,
          seatNumber: props.booking.seatNumber,  // Add seat number here
        },
        space: {
          name: props.worx.name,
          city: props.space.city,
          address: props.space.address,
          email: props.space.email,
          phone: props.space.phone,
          price: props.worx.price
        },
        user: {
          email: userEmail,
          name: userName
        }
      };

      await emailAPIService.sendUserBookingConfirmationEmail(body);
      await emailAPIService.sendHotelBookingConfirmationEmail(body);

    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isGuest && (!userName || !userEmail)) {
      setMessage("Please fill out your name and email.");
      return;
    }

    if (!stripe || !elements) {
      return; // Ensure Stripe.js is loaded
    }

    setIsLoading(true);

    await createBooking();

    if (props.isFree) {
      // If the booking is free, bypass Stripe and directly create a booking
      setIsLoading(false);
      navigate(`/completion?worxId=${props.worx.id}&spaceId=${props.space.id}&date=${props.booking.day}&hours=${props.booking.numHours}&price=0.0&useremail=${userEmail}`);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion?worxId=${props.worx.id}&spaceId=${props.space.id}&date=${props.booking.day}&hours=${props.booking.numHours}&price=${props.booking.price}&useremail=${userEmail}`,
      },
    });
    //TODO: IF Stripe payment fails, delete created booking

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className='pt-3 pb-3'>
      {/* If user is not logged in, show input fields for name and email */}
      {isGuest && (
        <div>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              id="name"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
        </div>
      )}

      {!props.isFree && (
        <>
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(event) => setUserEmail(event.value.email)}

          />
          <PaymentElement id="payment-element" options={{
            defaultValues: {
              billingDetails: {
                email: userEmail
              }
            }
          }} />
        </>
      )}

      <div className="flex justify-end">
        <button
          disabled={isLoading || (!stripe && !props.isFree) || !elements}
          id="submit"
          className="bg-orange-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-orange-600 mt-2"
        >
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : props.isFree ? "Book Now" : "Pay Now"}
          </span>
        </button>
      </div>

      {message && <div id="payment-message" className="text-red-500">{message}</div>}
    </form>
  );
}
