import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './login.module.css';
import Register2 from '../../../registration/register2.tsx';

export default function LoginVenue() {

  const location = useLocation();
  const [isLogin, setIsLogin] = useState(() => typeof location.state?.isLogIn === 'boolean' ? location.state?.isLogIn : true);
  const [showForm, setShowForm] = useState(false);
  const [userType] = useState('2');  // Set to '2' for venue user type
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState(isLogin ? 'signIn' : 'signUp');

  const handleSignInActive = () => {
    setActiveOption('none');
    setTimeout(() => {
      setIsLogin(true);
      setActiveOption('signIn');
      setShowForm(false);
    });
  };

  const handleSignUpActive = () => {
    setActiveOption('none');
    setTimeout(() => {
      setIsLogin(false);
      setActiveOption('signUp');
      setShowForm(false);
    });
  };

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <div className={`w-screen min-h-screen overflow-y-scroll overflow-x-hidden flex flex-col items-center relative ${styles.loginBody}`}>
      <div id="container" className={`${styles.container} my-auto ${activeOption === 'signUp' ? styles.rightPanelActive : ''}`}>
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            <div className={`${styles.overlayPanel} ${styles.overlayLeft} ${activeOption === 'signUp' ? styles.overlayLeftActive : ''}`}>
              <img
                src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
                alt="WORXINN"
                onClick={handleLogoClick}
                className={styles.logo_cursor}
              />
              <h1>Join Us as a Venue Partner!</h1>
              <p>Sign up to list your venue, attract new customers, and grow your business with WORXINN.</p>
              <button className={`${styles.ghost} animate-pulse`} onClick={handleSignInActive}>Login</button>
            </div>
            <div className={`${styles.overlayPanel} ${styles.overlayRight} ${activeOption === 'signUp' ? styles.overlayRightActive : ''}`}>
              <img
                className="object-cover mb-2"
                src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
                alt="WORXINN"
                onClick={handleLogoClick}
                className={styles.logo_cursor}
              />
              <h1>Welcome Back, Venue Partner!</h1>
              <p>Log in to manage your spaces, view bookings, and connect with our community.</p>
              <button className={`${styles.ghost} animate-pulse`} onClick={handleSignUpActive}>Register</button>
            </div>
          </div>
        </div>
        {activeOption === 'signUp' && (
          <div className={`${styles.formContainer} ${styles.signUpContainer} fade-in-2`}>
            <div className='flex flex-col justify-center items-center h-full gap-2'>
              <Register2 isLogin={false} userType={'2'} />
            </div>
          </div>
        )}
        {activeOption === 'signIn' && (
          <div className={`${styles.formContainer} ${styles.signInContainer} fade-in`}>
            <div className='flex flex-col justify-center items-center h-full gap-2'>
              <Register2 isLogin={true} userType={'2'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
