import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import './HoverEffectDiv.css';

const HoverEffectDiv = ({ text, onClick, isSelected, disabled = false, tooltipMessage = "Disabled" }) => {
  const [hovered, setHovered] = useState(false);
  const [displayText, setDisplayText] = useState(text);


  const getRandomChar = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  const revertText = () => {
    let index = 0;
    const revertInterval = setInterval(() => {
      setDisplayText((prevText) =>
        prevText
          .split('')
          .map((char, i) => (i <= index ? text.charAt(i) : char))
          .join('')
      );
      index += 1;
      if (index >= text.length) {
        clearInterval(revertInterval);
      }
    }, 50);
  };

  return (
    <Tooltip title={disabled ? tooltipMessage : ''} disableHoverListener={!disabled}>
      <div
        className={`relative hoverEffectDiv ${isSelected ? 'selected' : ''} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={!disabled ? onClick : undefined}
      >
        {displayText}
      </div>
    </Tooltip>
  );
};

export default HoverEffectDiv;
