import React, { useState, useEffect, useRef } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const stats = [
    { label: "Bleisure travelers interested in on-site coworking", value: 47, unit: "%" },
    { label: "Irish employees working mostly from home", value: 21.4, unit: "%" },
    { label: "UK workforce working Remote or Hybrid", value: 41, unit: "%" },
    { label: "US respondents planning to add leisure to business trips", value: 60, unit: "%" },
    { label: "US respondents planning to work during leisure trips", value: 50, unit: "%" },
]

const marketSize = [
    { year: 2023, value: 594, unit: "B" },
    { year: 2033, value: 3500, unit: "B" },
]

const Card = ({ children, title, description }: { children: React.ReactNode; title: string; description: string }) => (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8 font-ibm-plex-mono">
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        {children}
    </div>
)

const CustomTooltip = ({ active, payload, label }: { active?: boolean; payload?: any[]; label?: string }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white border border-gray-200 p-2 shadow-md font-ibm-plex-mono">
                <p className="font-bold">{label}</p>
                <p>{`${payload[0].value}${payload[0].payload.unit}`}</p>
            </div>
        )
    }
    return null
}

const StatCard = ({ label, value, unit }: { label: string; value: number; unit: string }) => (
    <div className="bg-white shadow-md rounded-lg p-6 m-2 font-ibm-plex-mono text-center">
        <h4 className="text-2xl font-bold mb-2">{value}{unit}</h4>
        <p className="text-sm">{label}</p>
    </div>
)

const ProgressBar = ({ progress }: { progress: number }) => (
    <div className="w-full bg-gray-200 h-1 mt-4">
        <div
            className="bg-orange-500 h-1 transition-all duration-300 ease-linear"
            style={{ width: `${progress}%` }}
        />
    </div>
)

export default function BleisureStats() {
    const [progress, setProgress] = useState(0)
    const sliderRef = useRef<Slider>(null)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    if (sliderRef.current) {
                        sliderRef.current.slickNext()
                    }
                    return 0
                }
                return Math.min(oldProgress + 1, 100)
            })
        }, 100)

        return () => {
            clearInterval(timer)
        }
    }, [])

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        beforeChange: () => setProgress(0),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="space-y-8 font-ibm-plex-mono">
            <style>{`
                @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');
                
                .font-ibm-plex-mono {
                    font-family: 'IBM Plex Mono', monospace;
                }
            `}</style>

            <Card title="Key Bleisure Travel Statistics" description="Important trends in the bleisure travel market">
                <Slider ref={sliderRef} {...sliderSettings}>
                    {stats.map((stat, index) => (
                        <StatCard key={index} {...stat} />
                    ))}
                </Slider>
                <ProgressBar progress={progress} />
            </Card>

            <Card title="Global Bleisure Tourism Market Growth" description="Market value projection from 2023 to 2033 (in billion USD)">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={marketSize}>
                        <XAxis
                            dataKey="year"
                            style={{ fontFamily: 'IBM Plex Mono' }}
                        />
                        <YAxis
                            tickFormatter={(value) => `$${value}B`}
                            style={{ fontFamily: 'IBM Plex Mono' }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="value" fill="#FF4900" />
                    </BarChart>
                </ResponsiveContainer>
            </Card>

            <Card title="Additional Bleisure Travel Insights">
                <ul className="list-disc pl-5 space-y-2">
                    <li>Bleisure travelers take an average of 6.4 trips per year globally</li>
                    <li>30% of bleisure trips include attendance at an internal company meeting</li>
                    <li>The average bleisure trip in the USA costs $1,566 (2023)</li>
                    <li>33% of blended travelers value food and drink as a top benefit of work-from-hotel choices</li>
                </ul>
            </Card>
        </div>
    )
}