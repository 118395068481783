import React from 'react';
import styles from './footer.module.css';
import HoverEffectText from '../../effects/SocialEffectMenu';
import Card from './card';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.title}>© {new Date().getFullYear()} WorxInn. All rights reserved.</div>
      </div>
      <div className={styles.cardsContainer}>
        <Card
          title="Additional Information"
          subtitle={["Contact: info@worxInn.com", "Phone: (123) 456-7890"]}
          iconClass="fa-solid fa-info-circle"
        />
        <Card
          title="Address"
          subtitle={["32 Washington St.", "Cork, Ireland T12 T880"]}
          iconClass="fa-solid fa-map-marker-alt"
        />
        <Card
          title="Social Media"
          iconClass="fa-solid fa-share-alt"
        >
          <HoverEffectText text="Instagram" url='https://www.instagram.com/worxinn/' />
          <HoverEffectText text="LinkedIn" url='https://www.linkedin.com/company/worxinn/' />
        </Card>
        <Card
          title="Policies"
          iconClass="fa-solid fa-file-alt"
        >
          <HoverEffectText text="Support" url='#' />
          <HoverEffectText text="Feedback" url='/feedback' />
          <HoverEffectText text="Privacy Policy" url='#' />
          <HoverEffectText text="Terms and Conditions" url='#' />
        </Card>
      </div>
    </footer>
  );
}

export default Footer;
