import React, { useEffect, useRef, useState } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { ArrowRightIcon, CheckIcon, LightningBoltIcon, PersonIcon, RocketIcon } from '@radix-ui/react-icons'
import styles from './VenuePartner.module.css'
import Header from './components/header'
import Footer from './components/footer'
import ImageSlider from './components/imageSlider'
import BleisureStats from './components/BleisureStats'
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from './components/header/header_mui'


const useScrollAnimation = (ref: React.RefObject<HTMLElement>) => {
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['start end', 'end start']
    })

    const opacity = useTransform(scrollYProgress, [0, 0.3, 1], [0, 1, 1])
    const y = useTransform(scrollYProgress, [0, 0.1, 1], [100, 0, 0])

    return { opacity, y }
}

const Section = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => {
    const ref = useRef(null)
    const { opacity, y } = useScrollAnimation(ref)

    return (
        <motion.section
            ref={ref}
            style={{ opacity, y }}
            className={`${styles.section} ${className}`}
        >
            {children}
        </motion.section>
    )
}

const WorkspaceType = ({ title, description, icon }: { title: string; description: string; icon: React.ReactNode }) => (
    <div className={styles.workspaceCard}>
        <div className={styles.workspaceIcon}>{icon}</div>
        <h3 className={styles.workspaceTitle}>{title}</h3>
        <p className={styles.workspaceDescription}>{description}</p>
    </div>
)

const handleSignIn = (navigate: ReturnType<typeof useNavigate>) => {
    navigate('/venue/login', { state: { isLogIn: false } });

};

const handleLogoClick = () => {
    navigate('/')
}


export default function HotelLandingPage() {
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 500)
        return () => clearTimeout(timer)
    }, [])

    return (
        <div className={`${styles.container} min-h-screen flex flex-col`}>
            <img
                src="logos/Logo_black.png"
                alt="WORXINN"
                onClick={handleLogoClick}
                className={styles.logo_cursor}
            />
            <div className={styles.containerInside}>
                <main className={styles.main}>
                    <Section className={styles.heroSection}>
                        <motion.h1
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
                            transition={{ duration: 0.8 }}
                            className={styles.heroTitle}
                        >
                            Grow your sales to Remote and Bleisure sectors with WorXInn as your sales channel. <br /><br />
                            Position your hotel to attract and Grow the Remote and Bleisure Market
                        </motion.h1>
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: isVisible ? 1 : 0 }}
                            transition={{ duration: 0.8, delay: 0.3 }}
                            className={styles.heroSubtitle}
                        >
                            WorXInn: Your Hotel's New Sales Channel for Remote and Hybrid Workers
                        </motion.p>
                        <motion.button
                            onClick={() => handleSignIn(navigate)}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.5 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                            className={styles.button}
                        >
                            Partner with Us
                        </motion.button>
                    </Section>

                    <ImageSlider />

                    <Section id="statistics" className={styles.statsSection}>
                        <h2 className={styles.sectionTitle}>Bleisure Travel Market Insights</h2>
                        <BleisureStats />
                    </Section>

                    <Section id="workspaces">
                        <h2 className={styles.sectionTitle}>Our Workspace Types</h2>
                        <div className={styles.workspaceGrid}>
                            <WorkspaceType
                                title="WorXSpots"
                                description="Is simply an existing table in the lounge with a socket. You are already offering it with little or no return."
                                icon={<PersonIcon className="w-8 h-8 text-orange-600" />}
                            />
                            <WorkspaceType
                                title="WorXZones"
                                description="A Dedicated Workspace, in an existing or re-aligned area, in or near Lobby, or maybe The old Business Centre re-imagined"
                                icon={<RocketIcon className="w-8 h-8 text-orange-600" />}
                            />
                            <WorkspaceType
                                title="WorXOffices"
                                description="A dedicated Own key Office Space. A problem bedroom or a now redundant Backoffice repurposed to accommodate 2-6 individual users who require a quieter space and security"
                                icon={<LightningBoltIcon className="w-8 h-8 text-orange-600" />}
                            />
                            <WorkspaceType
                                title="WorXSuites"
                                description="A high specked room madeover for With Corporate Teams in mind. Maybe a local HQ or a regular local meetup Workspace. These spaces Supplement MICE."
                                icon={<CheckIcon className="w-8 h-8 text-orange-600" />}
                            />
                        </div>
                    </Section>

                    <Section id="meetings" className={styles.graySection}>
                        <div className={styles.contentWrapper}>
                            <h2 className={styles.sectionTitle}>WorXInnMeetings</h2>
                            <p className={styles.heroSubtitle}>Seamless real-time meeting room booking for your hotel's business clients and Remote Teams.</p>
                            <ul className={styles.featureList}>
                                {['Easy online booking', 'Bookable by the hour', 'Quick Turnaround', 'Multiple bookings per day', 'Make a small meeting room bookable in real-time'].map((feature, index) => (
                                    <motion.li
                                        key={index}
                                        initial={{ opacity: 0, x: -50 }}
                                        whileInView={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                        className={styles.featureItem}
                                    >
                                        <CheckIcon className={styles.featureIcon} />
                                        {feature}
                                    </motion.li>
                                ))}
                            </ul>
                        </div>
                    </Section>

                    <Section id="offers">
                        <div className={styles.contentWrapper}>
                            <h2 className={styles.sectionTitle}>WorXOffers</h2>
                            <div className={styles.offerCard}>
                                <h3 className={styles.offerTitle}>Coming Soon: Exclusive Deals for Workers</h3>
                                <p className={styles.offerDescription}>
                                    WorXOffers provides you unrivalled upselling opportunities to market and sell your fantastic amenities and services directly to Remote Workers and Bleuisure Travellers. Giving you a competitive advantage over non-partner hotels and AirBnB Competitors.
                                </p>
                                <ul className={styles.offerList}>
                                    {['Workspace + Service bundles like Workspace + Breakfast', 'Partnerships with local / National Employers', 'Sell & Market directly to Remote Workers and their Employers'].map((offer, index) => (
                                        <motion.li
                                            key={index}
                                            initial={{ opacity: 0, y: 20 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: index * 0.1 }}
                                            className={styles.offerItem}
                                        >
                                            <ArrowRightIcon className={styles.featureIcon} />
                                            {offer}
                                        </motion.li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Section>

                    <Section className={styles.heroSection}>
                        <h2 className={styles.sectionTitle}>Ready to Transform Your Hotel?</h2>
                        <p className={styles.heroSubtitle}>Let WorXInn help you reach Remote Workers and Bleisure Travellers.</p>
                        <button className={styles.button} onClick={() => handleSignIn(navigate)}>
                            Partner with Us
                        </button>
                    </Section>
                </main>
            </div >
            <Footer />
        </div >
    )
}